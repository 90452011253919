import SpineApi, { SPINE_API_URL } from '@/api/base/SpineApi'

export default {
  fetchAddress(searchKey, page) {
    return SpineApi.get(`${SPINE_API_URL}/fulfilment/search?q=${searchKey}&page=${page}`)
  },
  addAddress(data) {
    return SpineApi.post(`${SPINE_API_URL}/fulfilment/address`, data)
  },
  fetchAddresses() {
    return SpineApi.get(`${SPINE_API_URL}/fulfilment/addresses`)
  },
  addGooglePlaceAddress(data) {
    return SpineApi.post(`${SPINE_API_URL}/fulfilment/address`, data)
  },
  getLocationFromPostalCode(data) {
    return SpineApi.post(`${SPINE_API_URL}/fulfilment/location_from_postal_code`, data)
  }
}
