<template>
  <div class="OrderSummaryFooter">
    <two-columns>
      <div
        slot="right"
        class="SubtotalWrapper">
        <order-subtotal
          :leftColumnWidth="75"
          :order="order"
          :checkout="true">
          <tabulation-item
            v-if="order.menuDetails.eventType != 'MiniBuffet' && !isBentoMenu"
            slot="afterDeliveryFee"
            :leftColumnWidth="75">
            <form @submit.prevent="">
              <input
                type="submit"
                class="Hidden">
              <label
                class="FormLabel"
                v-html="stairsCopy"/>

              <label class="FormInputLabel">
                <input
                  :id="`order_${order.id}_need_stairs_yes`"
                  v-model="orderNeedStairs"
                  type="radio"
                  :value="true"
                  name="order_need_stairs"
                  class="Radio"
                  :class="{'Error': formErrorsVisible && orderNeedStairs === null}"
                  required>
                <span>
                  {{ $t('order.needStairsYes') }}
                </span>
              </label>
              <label class="FormInputLabel">
                <input
                  :id="`order_${order.id}_need_stairs_no`"
                  v-model="orderNeedStairs"
                  type="radio"
                  :value="false"
                  name="order_need_stairs"
                  class="Radio"
                  :class="{'Error': formErrorsVisible && orderNeedStairs === null}"
                  required>
                <span>
                  {{ $t('order.needStairsNo') }}
                </span>
              </label>
            </form>
            <template slot="right">
              <span class="Price">
                {{ $t('common.price', { price: formatCostWithDecimal(stairsCost) }) }}
              </span>
            </template>
          </tabulation-item>
        </order-subtotal>
      </div>
    </two-columns>
  </div>
</template>

<script>
import OrderSubtotal from '@/components/OrderSubtotal'
import { SET_ORDER_PROP } from '../../../actions'
import TabulationItem from '@/components/common/TabulationItem'
import TwoColumns from '@/components/common/TwoColumns'
import { formatCost } from '@/assets/js/common'
import { getStairsCost } from '@/assets/js/order-helper'
import { isBentoMenu } from '@/assets/js/highlight-menu-helper'

export default {
  name: 'order-summary-footer',
  components: {
    OrderSubtotal,
    TabulationItem,
    TwoColumns
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    formErrorsVisible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      orderNeedStairs: this.order.needStairs
    }
  },
  computed: {
    stairsCost() {
      return getStairsCost(this.order)
    },
    isBentoMenu() {
      return isBentoMenu(this.order.menuDetails.id)
    },
    stairsCopy() {
      return this.$t('order.needStairsLabel')
    }
  },
  watch: {
    order() {
      this.orderNeedStairs = this.order.needStairs
    },
    orderNeedStairs() {
      this.$store.dispatch(SET_ORDER_PROP, {
        index: this.index,
        prop: 'needStairs',
        value: this.orderNeedStairs
      })
    }
  },
  created() {
    if (this.isBentoMenu) {
      this.orderNeedStairs = false
    }
  },
  methods: {
    formatCost(cost) {
      return formatCost(cost)
    },
    formatCostWithDecimal(cost) {
      return parseFloat(cost).toFixed(2)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";
@import "~@/assets/css/form-input";

.OrderSummaryFooter {
  padding: $space-m;
  background-color: $white;
  border: 1px solid $gray-lighter;
  border-top: 0;
  @extend %body;
  @extend %bold_weight;
  color: $gray-darker;

  /deep/ .SubtotalWrapper {
    .FormInputLabel {
      display: inline-block;
      margin-right: $space-m;
    }
  }
}
</style>
