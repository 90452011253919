<template>
  <search-dropdown
    class="SearchAddress"
    :searchApi="searchApi"
    :options="searchResults"
    :getInputDisplay="getInputDisplay"
    :getOptionDisplay="getOptionDisplay"
    :getOptionDisplayRight="getOptionDisplayRight"
    :item="address"
    :placeholder="placeholder"
    :inputClass="inputClass"
    :disabled="disabled"
    @change="onChange"/>
</template>

<script>
import AddressApi from '@/api/AddressApi'

import SearchDropdown from '@/components/SearchDropdown'

export default {
  name: 'search-address',
  components: {
    SearchDropdown
  },
  props: {
    type: {
      type: String,
      default: 'address'
    },
    address: {
      type: Object,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    inputClass: {
      type: String,
      default: 'DefaultInput'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchResults: []
    }
  },
  methods: {
    async searchApi(searchTerm) {
      try {
        const result = (await AddressApi.fetchAddress(searchTerm, 1))
        const pageCount = Math.ceil(result.totalCount / 20)
        if (pageCount === 0) {
          this.searchResults = []
          return
        }
        this.searchResults = result.items
      } catch(e) {
        this.searchResults = []
      }
    },
    getInputDisplay(selectedItem) {
      if (this.type === 'postal_code') {
        return selectedItem.postalCode
      }
      return selectedItem.fullAddress || selectedItem.address
    },
    getOptionDisplay(item) {
      let display = item.address
      if (item.buildingName) {
        display += `, ${item.buildingName}`
      }
      return display
    },
    getOptionDisplayRight(item) {
      return item.postalCode || ''
    },
    onChange(address) {
      this.$emit('change', address)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_shared_variables.sass";

.SearchAddress {
  &.SearchDropdown /deep/ .DefaultInput {
    padding: $space-s $space-xs;
    border-radius: 0;
  }
}
</style>
