export function sortedAddresses(addresses, selectedAddressId) {
  const addressesClone = addresses.filter((address) => address.location)
  const defaultAddressIndex = addressesClone.findIndex((address) => address.id === selectedAddressId)
  const defaultAddress = addressesClone[defaultAddressIndex]
  if (defaultAddressIndex !== -1) {
    addressesClone.splice(defaultAddressIndex, 1)
    addressesClone.unshift(defaultAddress)
  }
  return addressesClone
}

export function formatAddress(address) {
  let formattedAddress = address.fullAddress || address.address || address.location.fullAddress || address.location.address
  if (address.blkTower) {
    formattedAddress += `, ${address.blkTower}`
  }
  if (address.floor) {
    formattedAddress += `, #${address.floor}-${address.unitNo}`
  }
  return formattedAddress
}
