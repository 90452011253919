<template>
  <modal
    name="add-address-modal"
    overlayTransition=""
    class="Modal AddAddressModal"
    @before-open="beforeOpen">
    <span
      class="ModalClose"
      @click="closeModal">
      ✕
    </span>
    <h3 class="ModalTitle">
      {{ $t('addAddressModal.title') }}
    </h3>
    <label
      for="add_address_address"
      class="FormLabel">
      {{ $t('addAddressModal.addressLabel') }}
    </label>
    <component
      :is="searchAddressComponent"
      :address="address"
      inputId="add_address_address"
      :inputClass="`FormInput${errorVisible && !address ? ' Error' : ''}`"
      :disabled="processing"
      @change="onChange"/>
    <two-columns :keepColumnWidth="true">
      <template>
        <label
          for="add_address_floor"
          class="FormLabel">
          {{ $t('addAddressModal.floorLabel') }}
        </label>
        <input
          id="add_address_floor"
          v-model="floor"
          type="text"
          class="FormInput"
          :class="{'Error': errorVisible && !floor}"
          :disabled="processing">
      </template>
      <template slot="right">
        <label
          for="add_address_unit_no"
          class="FormLabel">
          {{ $t('addAddressModal.unitNoLabel') }}
        </label>
        <input
          id="add_address_unit_no"
          v-model="unitNo"
          type="text"
          class="FormInput"
          :class="{'Error': errorVisible && !unitNo}"
          :disabled="processing">
      </template>
    </two-columns>
    <two-columns>
      <template>
        <label
          for="add_address_block_tower"
          class="FormLabel">
          {{ $t('addAddressModal.blockTowerLabel') }}
        </label>
        <input
          id="add_address_block_tower"
          v-model="blkTower"
          type="text"
          class="FormInput"
          :disabled="processing">
      </template>
      <template slot="right">
        <label
          for="add_address_company_name"
          class="FormLabel">
          {{ $t('addAddressModal.companyNameLabel') }}
        </label>
        <input
          id="add_address_company_name"
          v-model="companyName"
          type="text"
          class="FormInput"
          :disabled="processing">
      </template>
    </two-columns>
    <div class="ModalActions">
      <a
        class="Button Button--primary"
        :disabled="processing"
        @click="!processing ? addAddress() : null">
        {{ $t('addAddressModal.addAddressButtonTitle') }}
      </a>
    </div>
  </modal>
</template>

<script>
import AddressApi from '@/api/AddressApi'
import TwoColumns from '@/components/common/TwoColumns'
import SearchAddress from '@/components/SearchAddress'
import SearchGooglePlaces from '@/components/SearchGooglePlaces'

export default {
  name: 'add-address-modal',
  components: {
    TwoColumns,
    SearchAddress,
    SearchGooglePlaces
  },
  data() {
    return {
      caller: null,
      address: null,
      floor: null,
      unitNo: null,
      blkTower: null,
      companyName: null,
      processing: false,
      errorVisible: false
    }
  },
  computed: {
    googlePlacesEnabled() {
      return process.env.VUE_APP_GOOGLE_PLACES_ENABLED === 'true'
    },
    searchAddressComponent() {
      return this.googlePlacesEnabled ? 'search-google-places' : 'search-address'
    }
  },
  methods: {
    beforeOpen(event) {
      this.caller = event.params.caller
    },
    closeModal() {
      this.$modal.hide('add-address-modal')
    },
    onChange(address) {
      this.address = address
    },
    async addAddress() {
      if (this.address && this.floor && this.unitNo) {
        this.errorVisible = false
        this.processing = true
        const data = {
          floor: this.floor,
          unit_no: this.unitNo,
          blk_tower: this.blkTower,
          company_name: this.companyName
        }
        let result
        if (this.googlePlacesEnabled) {
          data.placeData = this.address
          result = (await AddressApi.addGooglePlaceAddress(data))
        } else {
          data.locationId = this.address.id
          result = (await AddressApi.addAddress(data))
        }

        this.processing = false
        if (result.addresses) {
          this.address = null
          this.floor = null
          this.unitNo = null
          this.blkTower = null
          this.companyName = null
          this.$emit('reloadAddresses', result.addresses, result.selectedAddressId, this.caller)
          this.closeModal()
        }
      } else {
        this.errorVisible = true
      }
    }
  }
}
</script>
