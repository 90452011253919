<template>
  <modal
    name="login-form-modal"
    overlayTransition=""
    class="Modal LoginFormModal"
    @opened="onOpened">
    <span
      class="ModalClose"
      @click="closeModal">
      ✕
    </span>
    <h2
      class="LoginFormModalHeader"
      v-html="$t('loginFormModal.title')"/>
    <form @submit.prevent="">
      <div
        v-if="error"
        class="Alert AlertError"
        v-html="error"/>
      <label
        for="login_popup_user_email"
        class="FormLabel">
        {{ $t('loginFormModal.emailLabel') }}
      </label>
      <input
        id="login_popup_user_email"
        v-model="form.email"
        type="email"
        autocomplete="email"
        class="FormInput"
        :disabled="processing"
        @keyup.enter="focusPassword">
      <label
        for="login_popup_user_password"
        class="FormLabel">
        {{ $t('loginFormModal.passwordLabel') }}
      </label>
      <input
        id="login_popup_user_password"
        v-model="form.password"
        type="password"
        autocomplete="current-password"
        class="FormInput"
        :disabled="processing"
        @keyup.enter="login">
      <div class="ForgotPassword">
        <a
          :href="`${spineUrl}/forgot-password`"
          target="_blank"
          class="Button--text Button--text-primary">
          {{ $t('loginFormModal.forgotPasswordLabel') }}
        </a>
      </div>
      <input
        v-if="processing"
        type="button"
        class="Button Button--primary"
        disabled
        :value="$t('loginFormModal.loginButtonProcessingTitle')">
      <input
        v-else
        type="button"
        class="Button Button--primary"
        :value="$t('loginFormModal.loginButtonTitle')"
        @click="login">
    </form>
  </modal>
</template>

<script>
import { SET_USER_DATA } from '../../../actions'
import UserApi from '@/api/UserApi'
import cookies from '@/assets/js/cookies'

export default {
  name: 'login-form-modal',
  data() {
    return {
      processing: false,
      form: {
        email: '',
        password: ''
      },
      error: null
    }
  },
  computed: {
    spineUrl() {
      return process.env.VUE_APP_SPINE_URL
    }
  },
  methods: {
    closeModal() {
      this.$modal.hide('login-form-modal')
    },
    onOpened() {
      this.focusEmail()
    },
    focusEmail() {
      const emailField = document.querySelector('#login_popup_user_email')
      if (emailField) {
        emailField.focus()
      }
    },
    focusPassword() {
      const passwordField = document.querySelector('#login_popup_user_password')
      if (passwordField) {
        passwordField.focus()
      }
    },
    blurPassword() {
      const passwordField = document.querySelector('#login_popup_user_password')
      if (passwordField) {
        passwordField.blur()
      }
    },
    preApiCall() {
      this.processing = true
      this.error = null
    },
    postApiCall(result) {
      if (result.error) {
        this.processing = false
        this.error = result.error
        this.$nextTick(this.focusPassword)
      } else {
        cookies.setCookie('external_access_token', result.accessToken.token)
        this.$store.dispatch(SET_USER_DATA, result)
        this.$emit('login', result.accessToken.token)
        this.closeModal()
      }
    },
    async login() {
      this.preApiCall()
      const result = (await UserApi.login(this.form))
      this.postApiCall(result)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/form-input";
.LoginFormModal {
  /deep/ {
    .v--modal {
      top: 50% !important;
      transform: translateY(-50%);
      width: 100% !important;
      max-width: 400px;
      max-height: 90%;
    }
  }
  .LoginFormModalHeader {
    margin-bottom: 1rem;
    font-weight: bold;
    text-align: center;
  }
  .FormInput {
    margin-bottom: 1.5rem;
  }
  .ForgotPassword {
    font-size: 14px;
    margin-bottom: 1.5rem;
  }
}
</style>
