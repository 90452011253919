<template>
  <div class="PaymentWallet">
    <template v-if="!manageWallet">
      <label
        id="payment-request-label"
        class="PaymentWallet__label"/>
      <div id="payment-request-button"/>
      <div class="OrBreak Hidden">
        <p>
          OR
        </p>
      </div>
    </template>
    <label
      v-if="paymentLabelTitle"
      class="PaymentWallet__label">
      {{ paymentLabelTitle }}
    </label>
    <div
      v-if="!paymentSourcesLoaded"
      class="PaymentWallet__loading">
      {{ $t('paymentWallet.loading') }}
    </div>
    <template v-else>
      <div
        class="PaymentWallet__cards"
        :class="{ disabled: apiProcessing }">
        <div
          v-for="source in sources"
          :key="source.id"
          class="PaymentWallet__card_container"
          @mouseover="$set(source, 'hover', true)"
          @mouseout="$set(source, 'hover', false)"
          @click="selectCard(source.id, $event)">
          <div
            class="PaymentWallet__card"
            :class="{ selected: source.id === defaultSource }">
            <i
              v-if="manageWallet"
              class="PaymentWallet__trash icon-delete"
              @click="removeCardConfirmation(source.id, source.last4)"/>
            <div
              class="PaymentWallet__radio Radio"
              :class="{
                selected: selectedCardId === source.id,
                hover: source.hover
              }"/>
            <label :class="{ manage: manageWallet }">
              <img
                class="PaymentWallet__card_icon"
                :src="getCardImageSrc(source.brand)">
              <template v-if="source.brand === 'American Express'">
                <span class="PaymentWallet__card_dots">
                  •••• ••••••
                </span>
                •{{ source.last4 }}
              </template>
              <template v-else>
                <span class="PaymentWallet__card_dots">
                  •••• ••••
                </span>
                •••• {{ source.last4 }}
              </template>
              <div
                v-if="source.id === defaultSource"
                class="PaymentWallet__default_label">
                {{ $t('paymentWallet.defaultLabel') }}
              </div>
              <div
                v-else-if="!alwaysSetAsDefault && !manageWallet && selectedCardId === source.id"
                class="PaymentWallet__set_as_default">
                <input
                  id="set-as-default"
                  v-model="setAsDefault"
                  type="checkbox"
                  class="PaymentWallet__checkbox Checkbox">
                <label for="set-as-default">
                  <span>
                    {{ $t('paymentWallet.setDefaultCardLabel') }}
                  </span>
                </label>
              </div>
            </label>
          </div>
        </div>
        <div
          class="PaymentWallet__card_container"
          @mouseover="hoverNewCard = true"
          @mouseout="hoverNewCard = false"
          @click="selectNewCard">
          <div class="PaymentWallet__card">
            <div
              class="PaymentWallet__radio Radio"
              :class="{
                selected: selectedCardId === null,
                hover: hoverNewCard
              }"/>
            <div id="payment-wallet-new-card"/>
          </div>
        </div>
      </div>
      <div
        v-if="alwaysSetAsDefault"
        class="PaymentWallet__always_default">
        <span>
          {{ getAlwaysSetAsDefaultMessage }}
        </span>
      </div>
      <div
        v-else-if="!manageWallet && (!guestCheckout || createGuestAccount)"
        class="PaymentWallet__add_to_wallet"
        :class="{ disabled: selectedCardId !== null }">
        <input
          id="add-to-wallet"
          v-model="addToSources"
          type="checkbox"
          class="PaymentWallet__checkbox Checkbox"
          :disabled="selectedCardId !== null">
        <label for="add-to-wallet">
          <span>
            {{ $t('paymentWallet.addCardLabel') }}
          </span>
        </label>
      </div>
      <div
        v-if="errorMessage || error"
        class="PaymentWallet__feedback error">
        <p>
          <i class="icon-alert_error"/>
          {{ errorMessage || error }}
        </p>
      </div>
      <div class="PaymentWallet__actions">
        <template v-if="manageWallet">
          <template v-if="!apiProcessing">
            <input
              v-if="selectedCardId === null"
              type="button"
              class="PaymentWallet__button Button Button--primary"
              :value="$t('paymentWallet.addCardTitle')"
              @click="createStripeToken(addNewCard)">
            <input
              v-else
              type="button"
              class="PaymentWallet__button Button Button--primary"
              :disabled="selectedCardId === defaultSource"
              :value="$t('paymentWallet.setDefaultCardTitle')"
              @click="setDefaultCard">
          </template>
          <template v-else>
            <input
              v-if="apiProcessing"
              type="button"
              class="PaymentWallet__button Button Button--primary"
              disabled="disabled"
              :value="$t('paymentWallet.processingTitle')">
          </template>
        </template>
        <template v-else>
          <input
            type="button"
            class="PaymentWallet__button Button Button--primary Button--full"
            :value="paymentButtonTitle"
            @click="startPayment()">
        </template>
      </div>
      <div
        v-if="successMessage"
        class="PaymentWallet__feedback success">
        <p>
          <i class="icon-alert_success"/>
          {{ successMessage }}
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser'
import { importScript } from '@/assets/js/common'
import paymentWalletApi from './_api'

export default {
  name: 'payment-wallet',
  props: {
    stripeKey: {
      type: String,
      required: true
    },
    paymentAmount: {
      type: Number,
      default: null
    },
    shippingOptions: {
      type: Object,
      default: null
    },
    paymentLabelTitle: {
      type: String,
      default: null
    },
    paymentButtonTitle: {
      type: String,
      default: null
    },
    alwaysSetAsDefault: {
      type: Boolean,
      default: false
    },
    alwaysSetAsDefaultMessage: {
      type: String,
      default: null
    },
    error: {
      type: String,
      default: null
    },
    guestCheckout: {
      type: Boolean,
      default: false
    },
    createGuestAccount: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      paymentSourcesLoaded: false,
      defaultSource: null,
      sources: [],
      stripe: null,
      stripeCard: null,
      selectedCardId: null,
      addToSources: true,
      setAsDefault: true,
      apiProcessing: false,
      errorMessage: null,
      successMessage: null,
      hoverNewCard: false
    }
  },
  computed: {
    manageWallet() {
      return this.paymentButtonTitle === ''
    },
    getAlwaysSetAsDefaultMessage() {
      return (
        this.alwaysSetAsDefaultMessage ||
        this.$t('paymentWallet.alwaysSetAsDefaultMessage')
      )
    },
    addToSourcesVisible() {
      return !this.manageWallet && (!this.guestCheckout || this.createGuestAccount)
    }
  },
  watch: {
    defaultSource() {
      this.initializePaymentWalletObject()
      window.PAYMENT_WALLET.DEFAULT_SOURCE = this.defaultSource
    },
    sources() {
      this.initializePaymentWalletObject()
      window.PAYMENT_WALLET.SOURCES = this.sources
    },
    apiProcessing() {
      if (this.apiProcessing) {
        this.errorMessage = null
        this.$emit('paymentWalletStartProcessing')
        window.dispatchEvent(
          this.createCustomEvent('paymentWalletStartProcessing')
        )
      } else {
        this.$emit('paymentWalletEndProcessing')
        window.dispatchEvent(
          this.createCustomEvent('paymentWalletEndProcessing')
        )
      }
    }
  },
  created() {
    if (this.guestCheckout) {
      this.paymentSourcesLoaded = true
      this.checkStripeMountElementExists()
    } else if (
      window.PAYMENT_WALLET &&
      window.PAYMENT_WALLET.DEFAULT_SOURCE &&
      window.PAYMENT_WALLET.SOURCES
    ) {
      this.paymentSourcesLoaded = true
      this.defaultSource = window.PAYMENT_WALLET.DEFAULT_SOURCE
      this.sources = window.PAYMENT_WALLET.SOURCES
      this.selectedCardId = this.defaultSource
      this.$nextTick(() => {
        this.initializeStripe()
      })
    } else {
      this.getPaymentSources()
    }
    if (!window.stripeScriptImported) {
      importScript('https://js.stripe.com/v3/')
      window.stripeScriptImported = true
    }
  },
  methods: {
    initializePaymentWalletObject() {
      window.PAYMENT_WALLET = window.PAYMENT_WALLET || {}
    },
    checkStripeMountElementExists() {
      if (document.querySelector('#payment-wallet-new-card')) {
        this.$nextTick(this.initializeStripe)
      } else {
        setTimeout(this.checkStripeMountElementExists, 100)
      }
    },
    async getPaymentSources() {
      const result = (await paymentWalletApi.getPaymentSources())
      this.paymentSourcesLoaded = true
      if (result) {
        this.defaultSource = result.defaultSource
        this.sources = result.sources
        this.selectedCardId = this.defaultSource
        this.$nextTick(() => {
          this.initializeStripe()
        })
      }
    },
    initializeStripe() {
      try {
        this.stripe = Stripe(this.stripeKey) // eslint-disable-line no-undef
        const elements = this.stripe.elements()

        const style = {
          base: {
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#4d4d4d',
            lineHeight: '16px',
            '::placeholder': {
              color: '#aab7c4'
            }
          },
          empty: {
            fontWeight: '400'
          },
          invalid: {
            color: '#ed0900',
            iconColor: '#ed0900'
          }
        }
        this.stripeCard = elements.create('card', {
          style: style,
          hidePostalCode: true
        })
        this.stripeCard.mount('#payment-wallet-new-card')
        this.stripeCard.on('focus', this.selectNewCard)
        this.stripeCard.on('change', this.onCardChange)

        this.initializeStripePaymentRequest(elements)
      } catch(e) {
        // on first import Stripe script it takes a while to load (async)
        // this may result in an error initializing Stripe
        // if failure is due to [Stripe is not defined], we will try again in a while
        if (e.message === 'Stripe is not defined') {
          setTimeout(this.initializeStripe, 100)
        }
      }
    },
    initializeStripePaymentRequest(elements) {
      if (!this.manageWallet) {
        const paymentRequest = this.stripe.paymentRequest({
          country: 'SG',
          currency: 'sgd',
          total: {
            label: 'Total',
            amount: this.paymentAmount
          },
          shippingOptions: this.shippingOptions ? [this.shippingOptions] : []
        })

        const paymentRequestButton = elements.create('paymentRequestButton', {
          paymentRequest: paymentRequest,
          style: {
            paymentRequestButton: {
              type: 'default',
              theme: 'dark',
              height: '44px'
            }
          }
        })

        paymentRequest.canMakePayment().then(result => {
          var paymentWalletElement = document.querySelectorAll(
            '.PaymentWallet'
          )[0]
          if (result) {
            paymentRequestButton.mount('#payment-request-button')
            if (result.applePay) {
              document.querySelector('#payment-request-label').innerHTML =
                'Apple Pay'
            } else {
              // source: https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser/9851769
              const isIE = /* @cc_on!@ */ false || !!document.documentMode
              const isEdge = !isIE && !!window.StyleMedia
              if (isEdge) {
                document.querySelector('#payment-request-label').innerHTML =
                  'Microsoft Pay'
              } else {
                document.querySelector('#payment-request-label').innerHTML =
                  'Google Pay'
              }
            }
            paymentWalletElement.querySelector('.OrBreak').classList.remove('Hidden')
          } else {
            document.querySelector('#payment-request-button').style.display = 'none'
            document.querySelector('#payment-request-label').style.display = 'none'
            paymentWalletElement.querySelector('.OrBreak').style.display = 'none'
          }
        })

        paymentRequest.on('token', e => {
          // Call this when you have processed the token data provided by the API.
          // Note that you must must call complete within 30 seconds.
          this.$emit('paymentRequestCompleteMethod', e.complete)

          this.startPayment(true)
          this.makePayment(e.token)
        })
      }
    },
    getCardImageSrc(brand) {
      switch (brand) {
        case 'Visa':
          return require('./_assets/visa.svg')
        case 'MasterCard':
          return require('./_assets/mastercard.svg')
        case 'American Express':
          return require('./_assets/amex.svg')
        default:
          return require('./_assets/card.svg')
      }
    },
    selectCard(id, event) {
      if (!event.target.classList.contains('PaymentWallet__trash')) {
        this.selectedCardId = id
      }
    },
    selectNewCard() {
      this.selectedCardId = null
      if (!this.stripeCard) {
        this.errorMessage = 'Stripe failed to initialize: please refresh your browser or try with a different browser, e.g. Chrome'
        return
      }
      if (!this.stripeCard._focused) {
        this.stripeCard.focus()
      }
    },
    onCardChange(event) {
      if (event.error) {
        this.errorMessage = event.error.message
        Sentry.captureMessage(event.error.message)
        Sentry.captureMessage(event.error)
      } else {
        this.errorMessage = null
      }
    },
    async createStripeToken(successCallback, failCallback) {
      if (this.stripe === null) {
        this.errorMessage = 'Stripe failed to initialize: please refresh your browser or try with a different browser, e.g. Chrome'
        failCallback({ message: 'Stripe failed to initialize: please refresh your browser or try with a different browser, e.g. Chrome' })
        return
      }
      this.apiProcessing = true
      const result = await this.stripe.createToken(this.stripeCard)
      if (result.error) {
        this.errorMessage = result.error.message
        this.apiProcessing = false
        if (failCallback) {
          failCallback(result.error)
        }
        Sentry.captureMessage(result.error.message)
        Sentry.captureMessage(result.error)
      } else {
        this.apiProcessing = false
        successCallback(result.token)
      }
    },
    async addNewCard(source) {
      this.apiProcessing = true
      const result = (await paymentWalletApi.addNewCard(source.id))
      if (result) {
        if (result.errorMessage) {
          this.errorMessage = result.errorMessage
        } else {
          this.sources.push(result.newSource)
          this.defaultSource = result.newSource.id
          this.selectedCardId = this.defaultSource
          this.stripeCard.clear()
          this.showSuccessMessage(
            this.$t('paymentWallet.addCardSuccess')
          )
        }
      }
      this.apiProcessing = false
    },
    async setDefaultCard() {
      this.apiProcessing = true
      const result = (await paymentWalletApi.setDefaultCard(this.selectedCardId))
      if (result) {
        this.defaultSource = result.defaultSource
        this.showSuccessMessage(
          this.$t(
            'paymentWallet.setDefaultCardSuccess'
          )
        )
      }
      this.apiProcessing = false
    },
    removeCardConfirmation(id, last4) {
      if (
        window.confirm(
          this.$t(
            'paymentWallet.removeCardConfirmation',
            { last4: last4 }
          )
        )
      ) {
        this.removeCard(id)
      }
    },
    async removeCard(id) {
      this.apiProcessing = true
      const result = (await paymentWalletApi.removeCard(id))
      if (result) {
        if (result.errorMessage) {
          this.errorMessage = result.errorMessage
        } else {
          this.sources = this.sources.filter(source => {
            return source.id !== result.removedSource.id
          })
          if (this.defaultSource === result.removedSource.id) {
            this.defaultSource = result.newDefaultSource
          }
          if (this.selectedCardId === result.removedSource.id) {
            this.selectedCardId = this.defaultSource
          }
          this.showSuccessMessage(
            this.$t('paymentWallet.removeCardSuccess')
          )
        }
      }
      this.apiProcessing = false
    },
    showSuccessMessage(message) {
      this.successMessage = message
      setTimeout(() => {
        this.successMessage = null
      }, 3000)
    },
    checkAddToSources(token) {
      this.makePayment(token, null, this.addToSourcesVisible ? this.addToSources : true)
    },
    checkSetAsDefault() {
      const setAsDefault =
        this.selectedCardId &&
        this.selectedCardId !== this.defaultSource &&
        this.setAsDefault
      const card = { id: this.selectedCardId }
      this.makePayment(null, card, setAsDefault)
    },
    createCustomEvent(type, detail = null) {
      if (typeof Event === 'function') {
        return new CustomEvent(type, detail)
      } else {
        const customEvent = document.createEvent('CustomEvent')
        customEvent.initCustomEvent(
          type,
          true,
          true,
          detail ? detail.detail : null
        )
        return customEvent
      }
    },
    startPayment(paymentRequest = false) {
      this.$emit('startStripePayment')
      window.dispatchEvent(this.createCustomEvent('startStripePayment'))
      if (paymentRequest) return
      if (this.selectedCardId === null) {
        this.createStripeToken(this.checkAddToSources, this.stopPayment)
      } else {
        this.checkSetAsDefault()
      }
    },
    stopPayment(error) {
      const payload = {
        error
      }
      this.$emit('endStripePayment', payload)
      window.dispatchEvent(
        this.createCustomEvent('endStripePayment', {
          detail: payload
        })
      )
    },
    makePayment(token, card, setAsDefault = false) {
      const payload = {
        token,
        card,
        setAsDefault: this.alwaysSetAsDefault ? true : setAsDefault
      }
      this.$emit('makeStripePayment', payload)
      window.dispatchEvent(
        this.createCustomEvent('makeStripePayment', {
          detail: payload
        })
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";

.PaymentWallet__loading {
  margin-top: 0.75rem;
}
.PaymentWallet__label {
  font-size: 18px;
  font-weight: bold;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;

  &#payment-request-label {
    display: block;
    margin-top: 0;
  }
}
.PaymentWallet__cards {
  text-align: left;
  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}
.PaymentWallet__card {
  position: relative;
  background-color: #fff;
  border: 1px solid $gray-light;
  border-radius: $round;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  padding: 0.75rem 0.5rem;
  cursor: pointer;
  .PaymentWallet__trash {
    float: right;
    font-style: normal;
    &:after {
      content: "✕";
      display: block;
      line-height: 16px;
    }
    &.icon-delete:after {
      content: none;
    }
  }
  .Radio {
    position: absolute;
    top: 1.25rem;
  }
  .PaymentWallet__card_icon {
    height: 0.875rem;
    margin-right: 5px;
  }
  label {
    margin-left: 2rem;
    line-height: 1rem;
    font-size: 0.875rem;
    color: #4d4d4d;
    font-family: $font-family;
    font-weight: bold;

    @media (max-width: 379px) {
      .PaymentWallet__card_dots {
        display: none;
      }
    }
  }
  .PaymentWallet__default_label {
    float: right;
    padding: 1px 6px;
    border-radius: 2px;
    background-color: $primary;
    color: $ink;
    font-size: 0.6875rem;
  }
  .StripeElement {
    margin-left: 2rem !important;
  }
}
.PaymentWallet__set_as_default,
.PaymentWallet__add_to_wallet {
  position: relative;
  line-height: 1rem;
  &:after {
    content: "";
    display: block;
    clear: both;
  }
  &.disabled {
    opacity: 0.3;
  }
  .Checkbox {
    margin-bottom: -3px;
  }
  label {
    margin: 0;
    cursor: pointer;
    span {
      font-size: 14px;
      font-weight: bold;
    }
  }
}
.PaymentWallet__set_as_default {
  text-align: right;
  float: right;
  top: 2px;
}
.PaymentWallet__add_to_wallet {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.PaymentWallet__always_default {
  span {
    font-size: 14px;
    font-weight: bold;
  }
}
.PaymentWallet__feedback {
  text-align: left;
  margin: 0.75rem 0;
  padding: 0.75rem 0.5rem;
  border-radius: $round;
  clear: both;

  p {
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
    .icon-alert_error,
    .icon-alert_success {
      position: absolute;
      left: 0;
      top: 2px;
    }
  }
  & + .PaymentWallet__actions {
    margin-top: 1rem;
  }
}
.PaymentWallet__feedback.error {
  background-color: $red-light;
  border: 1px solid $red;

  p {
    color: $gray-darker;
  }
}
.PaymentWallet__feedback.success {
  background-color: rgba(100, 207, 131, 0.2);
}
.PaymentWallet__actions {
  margin-top: 3rem;

  .PaymentWallet__button {
    margin-bottom: 0.75rem;
    &:disabled {
      opacity: 0.6;
    }
  }
  .Button--full {
    max-width: none;
  }
}
.PaymentWallet__actions + .PaymentWallet__feedback.success {
  margin-top: 0;
}

.PayWithCard .PaymentWallet {
  .StripeElement {
    background-color: transparent;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
    width: auto;
  }
}
</style>
