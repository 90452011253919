<template>
  <div
    class="SearchDropdown"
    @click="preventClose">
    <div
      class="InputContainerWrapper"
      @click="showSearchBox">
      <div
        class="InputContainer"
        :class="`${inputClass}${disabled ? ' Disabled' : ''}`">
        <span
          class="Input"
          :class="{'Placeholder': !selectedItemExists}">
          {{ inputDisplay }}
        </span>
        <img
          :src="require('@/assets/images/icons/icon-dropdown.svg')"
          width="14"
          height="15"
          class="InputDropdownArrow">
      </div>
    </div>
    <div
      v-if="searchBoxVisible"
      class="SearchBoxPanel">
      <div class="SearchInputContainerWrapper">
        <div class="SearchInputContainer">
          <div class="SearchInputWrapper">
            <input
              :id="id"
              v-model="searchTerm"
              v-focus
              type="search"
              class="SearchInput"
              :class="inputClass"
              required
              @keydown.enter.prevent="keyboardSelect"
              @keydown.up="keyboardHighlightPrevious"
              @keydown.down="keyboardHighlightNext">
          </div>
          <div class="ResultsWrapper">
            <ul class="ResultOptions">
              <li
                v-if="filteredSearchList.length === 0"
                class="ResultOption SearchText"
                :class="inputClass"
                :aria-disabled="isSearching">
                {{ getSearchText }}
              </li>
              <li
                v-for="(searchItem, i) in filteredSearchList"
                v-else
                :key="`searched-item-${searchItem.id}`"
                class="ResultOption"
                :class="{
                  [inputClass]: true,
                  'Hover': i === keyboardHighlightedIndex
                }"
                @click="selectItem(searchItem)"
                @mouseover="keyboardHighlight(i)">
                <div class="ResultOptionRow">
                  <div class="ResultOptionColumn ResultOptionColumnLeft">
                    {{ getOptionDisplay(searchItem) }}
                  </div>
                  <div
                    v-if="getOptionDisplayRight"
                    class="postal ResultOptionColumn ResultOptionColumnRight">
                    <strong>
                      {{ getOptionDisplayRight(searchItem) }}
                    </strong>
                  </div>
                  <div class="clear"/>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        v-if="poweredByGoogle"
        class="PoweredByGoogle">
        <img :src="require('@/assets/images/powered-by-google.png')">
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

const SEARCHING = 'Searching...'

export default {
  name: 'search-dropdown',
  props: {
    id: {
      type: String,
      default: null
    },
    searchApi: {
      type: Function,
      default: null,
      note: 'Search API that accepts an argument searchTerm to call when searching'
    },
    options: {
      type: Array,
      default() {
        return []
      },
      note: 'Array of options to show as searchList'
    },
    getInputDisplay: {
      type: Function,
      default(selectedItem) {
        return selectedItem.name
      },
      note: 'Method which accepts an argument selectedItem that returns what to show on the input display'
    },
    getOptionDisplay: {
      type: Function,
      default(item) {
        return item.name
      },
      note: 'Method which accepts an argument item that returns what to show in the search options display'
    },
    getOptionDisplayRight: {
      type: Function,
      default: null,
      note: 'Method which accepts an argument item that returns what to show in the search options display at the far right'
    },
    item: {
      type: Object,
      default: null
    },
    minChar: {
      type: Number,
      default: 3,
      note: 'Min. number of characters before searching'
    },
    placeholder: {
      type: String,
      default: null
    },
    inputClass: {
      type: String,
      default: 'DefaultInput'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    poweredByGoogle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedItem: null,
      searchBoxVisible: false,
      searchTerm: '',
      searchResultText: SEARCHING,
      isSearching: false,
      searchList: [],
      keyboardHighlightedIndex: null,
      debouncer: debounce(this.performSearchApi, 500),
      preventClickClose: false
    }
  },
  computed: {
    selectedItemExists() {
      return this.selectedItem && Object.keys(this.selectedItem).length > 0
    },
    characterCount() {
      return this.searchTerm.trim().length
    },
    inputDisplay() {
      if (this.selectedItemExists) {
        return this.getInputDisplay(this.selectedItem)
      }
      return this.placeholder || `Please enter ${this.minChar} or more characters`
    },
    getSearchText() {
      if (this.searchApi) {
        const result = this.minChar - this.characterCount
        return this.characterCount < this.minChar ? `Please enter ${result} or more characters` : this.searchResultText
      }
      return 'No results found'
    },
    filteredSearchList() {
      if (this.searchApi || this.characterCount === 0) {
        return this.searchList
      }

      const regex = new RegExp(this.formatWordForRegex(this.searchTerm), 'ig')
      return this.searchList.filter((item) => {
        return this.formatWordForRegex(item.name).match(regex)
      })
    }
  },
  watch: {
    options: {
      handler() {
        this.searchList = this.options
        if (this.searchList.length === 0) {
          this.searchResultText = 'No results found'
        }
        this.isSearching = false
      },
      immediate: true
    },
    item: {
      handler() {
        this.selectedItem = this.item
      },
      immediate: true
    },
    searchTerm() {
      if (this.searchApi) {
        this.searchResultText = SEARCHING
        this.isSearching = this.characterCount >= this.minChar
        this.debouncer.cancel()
        this.debouncer()
      } else {
        this.keyboardHighlightedIndex = null
      }
    }
  },
  created() {
    document.removeEventListener('click', this.hideSearchBox)
    document.addEventListener('click', this.hideSearchBox)
  },
  methods: {
    preventClose() {
      this.preventClickClose = true
    },
    showSearchBox() {
      if (!this.disabled) {
        this.searchBoxVisible = true
      }
    },
    hideSearchBox() {
      // on click anywhere except this element, hide search bar
      // use preventClickClose to negate hiding search triggered by click anywhere
      if (this.preventClickClose) {
        this.preventClickClose = false
      } else {
        this.searchBoxVisible = false
      }
    },
    formatWordForRegex(word) {
      if (word) return word.replace(/[^a-zA-Z0-9\s]/g, '')
      return word
    },
    selectItem(item) {
      this.selectedItem = item
      this.$emit('change', item)
      this.hideSearchBox()
    },
    keyboardHighlightPrevious() {
      if (this.keyboardHighlightedIndex === null) {
        this.keyboardHighlightedIndex = 0
      } else if (this.keyboardHighlightedIndex > 0) {
        this.keyboardHighlightedIndex--
        this.keyboardScrollIndexIncremented(false)
      }
    },
    keyboardHighlightNext() {
      if (this.keyboardHighlightedIndex === null) {
        this.keyboardHighlightedIndex = 0
      } else if (this.keyboardHighlightedIndex < this.filteredSearchList.length - 1) {
        this.keyboardHighlightedIndex++
        this.keyboardScrollIndexIncremented(true)
      }
    },
    keyboardHighlight(index) {
      this.keyboardHighlightedIndex = index
    },
    keyboardScrollIndexIncremented(bool) {
      this.$nextTick(() => {
        const highlightedOption = document.querySelector('.ResultOption.Hover')
        const wrapper = document.querySelector('.ResultsWrapper')
        if (highlightedOption && wrapper) {
          const offset = highlightedOption.offsetHeight * 2 / 3
          const wrapperTop = wrapper.offsetTop + wrapper.scrollTop
          const wrapperBottom = wrapperTop + wrapper.offsetHeight

          if (bool) {
            const highlightedOptionBottom = highlightedOption.offsetTop + highlightedOption.offsetHeight
            const canSeeOptionBottom = highlightedOptionBottom >= wrapperTop && highlightedOptionBottom <= wrapperBottom
            if (!canSeeOptionBottom) {
              // scroll till highlightedOption is at the bottom, with 2/3 of the next option visible
              wrapper.scrollTop = highlightedOptionBottom - wrapper.offsetHeight - wrapper.offsetTop + offset
            }
          } else {
            const canSeeOptionTop = highlightedOption.offsetTop >= wrapperTop && highlightedOption.offsetTop <= wrapperBottom
            if (!canSeeOptionTop) {
              // scroll till highlightedOption is at the top, with 2/3 of the previous option visible
              wrapper.scrollTop = highlightedOption.offsetTop - wrapper.offsetTop - offset
            }
          }
        }
      })
    },
    keyboardSelect() {
      if (this.filteredSearchList.length > 0) {
        this.selectItem(this.filteredSearchList[this.keyboardHighlightedIndex || 0])
      }
    },
    performSearchApi() {
      this.searchResultText = SEARCHING
      this.searchList = []
      if (this.characterCount >= this.minChar) {
        this.isSearching = true
        this.searchApi(this.searchTerm)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.SearchDropdown {
  position: relative;

  .InputContainerWrapper {
    cursor: pointer;

    .InputContainer {
      &.Disabled {
        background-color: $gray-lighter;
        color: $gray-dark;
      }
      .Input {
        color: $ink;

        &.Placeholder {
          color: $gray-dark;
        }
      }
      .InputDropdownArrow {
        float: right;
      }
    }
  }
  .SearchBoxPanel {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    margin-bottom: $space-m;
    background-color: white;
    border: 1px solid $gray;
    border-bottom-left-radius: $round;
    border-bottom-right-radius: $round;

    .SearchInputContainerWrapper {
      .SearchInputContainer {
        .SearchInputWrapper {
          .SearchInput {
            margin: 0;
            background-color: $gray-lightest;
            border: 0;
          }
        }
        .ResultsWrapper {
          max-height: 250px;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
          }
          &::-webkit-scrollbar-track {
            border-radius: $round;
            background-color: rgba(0,0,0,0.05);
          }
          &::-webkit-scrollbar-thumb {
            border-radius: $round;
            background-color: rgba(0,0,0,0.25);
          }
          .ResultOptions {
            list-style: none;
            margin: 0;
            padding-left: 0;

            .ResultOption {
              min-height: auto;
              margin: 0;
              padding: $space-xs;
              border: 0;
              cursor: pointer;

              &.Hover {
                background-color: $ink;
                color: white;
              }
              .ResultOptionRow {
                position: relative;
                width: 100%;

                .ResultOptionColumn {
                  box-sizing: border-box;
                  position: relative;
                  display: inline-block;
                  vertical-align: top;

                  &.ResultOptionColumnLeft {
                    width: 75%;
                  }
                  &.ResultOptionColumnRight {
                    width: 25%;
                    padding-left: $space-xs;
                    text-align: right;
                  }
                }
              }
            }
          }
        }
      }
    }
    .PoweredByGoogle {
      @include clear;

      img {
        float: right;
        height: 18px;
        margin: $space-xxs;
      }
    }
  }
  .DefaultInput {
    box-sizing: border-box;
    width: 100%;
    padding: 10px $space-s;
    border: 1px solid $gray;
    border-radius: $round;
    font-family: $font-family;
    @extend %body;
  }
}
</style>
