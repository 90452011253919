<template>
  <div class="OrderSummaryHeader">
    <two-columns
      v-if="!isFestiveAlaCarteMenu"
      :leftColumnWidth="100">
      <template>
        <div class="MenuTitle">
          {{ order.menuDetails.name }}
        </div>
      </template>
    </two-columns>
    <two-columns>
      <template v-if="!isFestiveAlaCarteMenu && !isMenuAddOnMenu">
        <div class="SelectedPrice">
          <!-- {{ $t('order.selectedPricePerPax', { price: pricePerPaxFormatted, priceWithGst: pricePerPaxWithGstFormatted }) }} -->
          <span class="Price">
            {{ $t('common.price', { price: pricePerPaxFormatted }) }}
          </span>
          <span class="PerGuest">
            /guest
          </span>
          <br>
          <span>
            {{ $t('common.priceWithGst', { priceWithGst: pricePerPaxWithGstFormatted }) }}
          </span>
        </div>
        <div class="SelectedPax">
          {{ $t('order.selectedPax', { count: order.selectedPax }) }}
        </div>
      </template>
      <template v-else-if="isFestiveAlaCarteMenu">
        <div class="MenuTitle">
          {{ order.menuDetails.name }}
        </div>
      </template>

      <template slot="right">
        <table class="Table">
          <tbody>
            <tr
              v-for="servingTime in servingTimes"
              :key="`serving-time-${servingTime.label}`"
              class="SelectedTime">
              <td>
                {{ servingTime.label }}
              </td>
              <td>
                {{ $momenttz(new Date(order.selectedDate)).format('MMM DD, YYYY') }},
                {{ $momenttz(new Date(servingTime.time)).format('h:mmA') }}
              </td>
            </tr>
            <tr
              v-if="order.menuDetails.eventType !== 'MiniBuffet'"
              class="TeardownTime">
              <td>
                {{ $t('order.teardownTimeLabel') }}
              </td>
              <td>
                {{ $momenttz(new Date(order.selectedDate)).format('MMM DD, YYYY') }},
                {{ $momenttz(addTime(new Date(lastServingTime), 2, 15)).format('h:mmA') }}
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </two-columns>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatCost } from '@/assets/js/common'
import { addTime } from '@/assets/js/datetime'
import { MENU_IDS, getCostWithGst, isMenuAddOnMenu } from '@/assets/js/order-helper'
import TwoColumns from '@/components/common/TwoColumns'

export default {
  name: 'order-summary-header',
  components: {
    TwoColumns
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'getActiveOrder',
      'getAddOnMenus',
      'isFestiveAlaCarteMenu'
    ]),
    isBentoBundlesMenu() {
      return (
        this.getActiveOrder.menuDetails.id === MENU_IDS.bentoBundles ||
        this.getActiveOrder.menuDetails.name.includes('Bento Bundles')
      )
    },
    isMenuAddOnMenu() {
      return isMenuAddOnMenu(this.order, this.getAddOnMenus)
    },
    pricePerPax() {
      return this.order.selectedMenuPricing.pricePerPax
    },
    pricePerPaxFormatted() {
      return formatCost(this.pricePerPax)
    },
    pricePerPaxWithGstFormatted() {
      return getCostWithGst(this.pricePerPax, this.order.selectedDate)
    },
    servingTimes() {
      const times = []
      if (this.order.menuDetails.type === 'SeminarMenu' || this.isBentoBundlesMenu) {
        if (this.morningBreakTimeVisible) {
          times.push({
            label: this.morningBreakTimeLabel,
            time: this.order.selectedMorningBreakTime
          })
        }
        times.push({
          label: this.lunchTimeLabel,
          time: this.order.selectedTime
        })
        if (this.afternoonBreakTimeVisible) {
          times.push({
            label: this.afternoonBreakTimeLabel,
            time: this.order.selectedAfternoonBreakTime
          })
        }
      } else {
        times.push({
          label: this.$t(`order.${this.order.isSelfPickup ? 'pickupTimeLabel' : 'servingTimeLabel'}`),
          time: this.order.selectedTime
        })
      }
      return times
    },
    lastServingTime() {
      return this.servingTimes[this.servingTimes.length - 1].time
    },
    morningBreakTimeVisible() {
      if (this.isBentoBundlesMenu) {
        return this.order.selectedAddOns.some(addOn => {
          return addOn.item.name.includes('Morning Bundle') || addOn.item.name.includes('Full Day Bundle')
        })
      }
      return this.morningBreakTimeLabel.indexOf('Lunch') === -1
    },
    afternoonBreakTimeVisible() {
      if (this.isBentoBundlesMenu) {
        return this.order.selectedAddOns.some(addOn => {
          return addOn.item.name.includes('Afternoon Bundle') || addOn.item.name.includes('Full Day Bundle')
        })
      }
      return this.afternoonBreakTimeLabel.indexOf('Lunch') === -1
    },
    morningBreakTimeLabel() {
      if (this.isBentoBundlesMenu) return 'Breakfast time'
      return `${this.order.selectedMenuPricing.menuSections[0].name} time:`
    },
    afternoonBreakTimeLabel() {
      if (this.isBentoBundlesMenu) return 'Tea break time'
      return `${this.order.selectedMenuPricing.menuSections[this.order.selectedMenuPricing.menuSections.length - 1].name} time:`
    },
    lunchTimeLabel() {
      return 'Lunch time:'
    }
  },
  methods: {
    addTime(date, hours, minutes = 0) {
      const newDate = addTime(date, hours, minutes)
      const latestTime = this.$momenttz().startOf('day').add(22, 'hours').add(30, 'minutes').toDate()
      return newDate > latestTime ? latestTime : newDate
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.OrderSummaryHeader {
  padding: $space-m;
  background-color: $gray-lighter;
  color: $gray-darker;

  /deep/ .TwoColumns > .GridColumn {
    @media (max-width: 640px) {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .MenuTitle {
    margin-bottom: $space-xxs;
    @extend %display_medium;
    color: $ink;
  }
  .SelectedPrice {
    @extend %small;
    @extend %bold_weight;
    color: $ink;

    .Price {
      @extend %display_pricing;
    }
    .PerGuest {
      vertical-align: top;
      position: relative;
      top: $space-xxs;
    }
  }

  .SelectedPax {
    margin-top: $space-xs;
    @extend %body;
    @extend %bold_weight;
    color: $ink;
  }

  .Table {
    border-spacing: 0;

    tr:not(:last-child) {
    }
    td:first-child {
      padding: 0 0.5rem 0 0;
    }
  }
  .SelectedTime {
    @extend %heading;
    color: $ink;
  }

  .TeardownTime {
    @extend %heading;
    font-weight: normal;
  }
}
</style>
