<template>
  <div class="OrderSummaryBody">
    <two-columns
      v-for="(menuSections, menuSectionsSetIndex) in chunk(filteredMenuSections, 2)"
      :key="`order-${order.id}-menu-sections-set-${menuSectionsSetIndex}`">
      <div
        v-for="(menuSection, menuSectionIndex) in menuSections"
        :key="`order-${order.id}-menu-section-set-${menuSectionsSetIndex}-section-${menuSectionIndex}`"
        :slot="menuSectionIndex === 1 ? 'right' : 'default'"
        class="MenuSection">
        <div class="MenuSectionTitle">
          {{ menuSection.name }}
        </div>
        <template v-if="menuSection.name !== $t('addOns.title')">
          <div
            v-for="(menuSectionMeal, mealIndex) in getSelectedMenuSectionMealsArray(menuSection)"
            :key="`order-${order.id}-menu-section-set-${menuSectionsSetIndex}-section-${menuSectionIndex}-meal-${mealIndex}`"
            class="MenuSectionMeal">
            <span
              class="MealTitle"
              v-html="getMealName(menuSectionMeal)"/>
            <span
              v-if="menuSectionMeal.additionalChargePerPax"
              class="MealAdditionalChargePerPax">
              {{ $t('common.additionalCharge', { additionalCharge: formatCost(menuSectionMeal.additionalChargePerPax) }) }}
            </span>
            <span
              class="MealByline"
              v-html="`- ${menuSectionMeal.meal.byline}`"/>
            <div class="MealIcons">
              <img
                v-if="isMealVegetarian(menuSectionMeal)"
                src="@/assets/images/icons/icon-vegetarian.svg"
                class="MealIcon">
              <img
                v-if="isMealVegan(menuSectionMeal)"
                src="@/assets/images/icons/icon-vegan.svg"
                class="MealIcon">
              <img
                v-if="isMealSpicy(menuSectionMeal)"
                src="@/assets/images/icons/icon-spicy.svg"
                class="MealIcon">
              <img
                v-if="menuSectionMeal.recommended"
                src="@/assets/images/icons/icon-chef-hat.svg"
                class="MealIcon">
            </div>
          </div>
        </template>
        <template v-else>
          <div
            v-for="(addOn, addOnIndex) in order.selectedAddOns"
            :key="`order-${order.id}-add-ons-section-${menuSectionIndex}-add-on-${addOnIndex}`"
            class="MenuSectionMeal">
            <span
              class="MealTitle"
              v-html="`${addOn.quantity}x ${getMealName(addOn)}`"/>
            <span
              v-if="addOn.item.byline"
              class="MealByline"
              v-html="`- ${addOn.item.byline}`"/>
            <div class="MealIcons">
              <img
                v-if="isMealVegetarian(addOn)"
                src="@/assets/images/icons/icon-vegetarian.svg"
                class="MealIcon">
              <img
                v-if="isMealVegan(addOn)"
                src="@/assets/images/icons/icon-vegan.svg"
                class="MealIcon">
            </div>
          </div>
        </template>
      </div>
    </two-columns>
    <two-columns v-if="order.selectedFilters.length > 0">
      <div class="FilterSection">
        <div class="FilterSectionTitle">
          {{ $t('order.specialInstructionsLabel') }}
        </div>
        <span
          v-for="filter in sortedFilters"
          :key="`filter-${filter}`"
          class="FilterElement">
          {{ filter }}
        </span>
      </div>
    </two-columns>
  </div>
</template>

<script>
import chunk from 'lodash/chunk'
import { filters } from '@/assets/js/common'
import TwoColumns from '@/components/common/TwoColumns'

export default {
  name: 'order-summary-body',
  components: {
    TwoColumns
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    sortedFilters() {
      const selectedFiltersClone = this.order.selectedFilters.concat()
      return selectedFiltersClone.sort((a, b) => {
        return filters.indexOf(a) - filters.indexOf(b)
      })
    },
    filteredMenuSections() {
      let menuSections = this.order.menuSections.filter(menuSection => {
        if (menuSection.selectedMenuSectionMeals) {
          return Array.isArray(menuSection.selectedMenuSectionMeals)
            ? menuSection.selectedMenuSectionMeals.length > 0
            : menuSection.selectedMenuSectionMeals
        }
        return false
      })
      if (this.order.selectedAddOns && this.order.selectedAddOns.length > 0) {
        menuSections = menuSections.concat({ name: this.$t('addOns.title') })
      }
      return menuSections
    }
  },
  methods: {
    chunk(array, size) {
      return chunk(array, size)
    },
    formatCost(cost) {
      return parseFloat(cost).toFixed(2)
    },
    getSelectedMenuSectionMealsArray(menuSection) {
      if (Array.isArray(menuSection.selectedMenuSectionMeals)) {
        return menuSection.selectedMenuSectionMeals
      } else {
        return menuSection.selectedMenuSectionMeals
          ? [menuSection.selectedMenuSectionMeals]
          : []
      }
    },
    getMealName(menuSectionMeal) {
      return (menuSectionMeal.meal || menuSectionMeal.item).name.replace(/\s\(V\)/g, '').replace(/\s\(Vg\)/g, '')
    },
    isMealVegetarian(menuSectionMeal) {
      return (menuSectionMeal.meal || menuSectionMeal.item).name.includes('(V)')
    },
    isMealVegan(menuSectionMeal) {
      return (menuSectionMeal.meal || menuSectionMeal.item).name.includes('(Vg)')
    },
    isMealSpicy(menuSectionMeal) {
      return (/spicy/i).test(menuSectionMeal.meal.tags)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.OrderSummaryBody {
  padding: 1rem;
  background-color: $white;
  border: 1px solid $gray-lighter;
  @extend %body;
  color: $gray-darker;

  /deep/ .TwoColumns:not(:last-child) > .GridColumn {
    margin-bottom: $space-l;
  }
  .MenuSectionTitle {
    margin-bottom: $space-xs;
    @extend %small;
    @extend %bold_weight;
    color: $gray-dark;
    text-transform: uppercase;
  }
  .MenuSectionMeal:not(:last-child) {
    margin-bottom: $space-s;
  }
  .MealTitle, .MealAdditionalChargePerPax {
    @extend %bold_weight;
    color: $ink;
  }
  .MealByline {
    @extend %body;
  }
  .MealIcons {
    display: inline-block;
    margin-left: 2px;

    .MealIcon {
      height: 18px;
      vertical-align: text-bottom;

      & + .MealIcon {
        margin-left: 4px;
      }
    }
  }
  .FilterSection {
    font-size: 12px;
    color: $gray-dark;

    .FilterSectionTitle {
      margin-bottom: $space-xxs;
      font-size: 14px;
    }
    .FilterElement:not(:last-child) {
      margin-right: $space-m;
    }
  }
}
</style>
