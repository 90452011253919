<template>
  <modal
    :name="name"
    overlayTransition=""
    class="Modal AddressSelectorModal">
    <span
      class="ModalClose"
      @click="closeModal">
      ✕
    </span>
    <h3 class="ModalTitle AddressSelectorModalTitle">
      {{ title || $t('addressSelectorModal.defaultTitle') }}
    </h3>
    <div class="AddAddress">
      <a
        class="Button--text"
        @click="showAddAddressModal">
        {{ $t('addressSelectorModal.addAddress') }}
      </a>
    </div>
    <div class="AddressList">
      <label
        v-for="address in sortedAddresses"
        :key="`address-${address.id}`"
        class="AddressLabel"
        :for="`address_${address.id}`"
        @click="delaySelectAddress">
        <input
          :id="`address_${address.id}`"
          v-model="selectedAddress"
          :value="address"
          type="radio"
          class="Radio"
          name="selected_address">
        <div class="RadioLabel">
          <div class="FullAddress">
            {{ formatFullAddress(address) }}
          </div>
        </div>
      </label>
    </div>
  </modal>
</template>

<script>
import { formatAddress, sortedAddresses } from '@/assets/js/address-helper'

export default {
  name: 'address-selector-modal',
  props: {
    name: {
      type: String,
      default: 'address-selector-modal'
    },
    title: {
      type: String,
      default: null
    },
    addresses: {
      type: Array,
      required: true
    },
    selectedAddressId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      selectedAddress: null
    }
  },
  computed: {
    sortedAddresses() {
      return sortedAddresses(this.addresses, this.selectedAddressId)
    },
    defaultAddress() {
      return this.sortedAddresses[0]
    }
  },
  watch: {
    defaultAddress() {
      this.selectedAddress = this.defaultAddress
    }
  },
  created() {
    this.selectedAddress = this.defaultAddress
  },
  methods: {
    closeModal() {
      this.$modal.hide(this.name)
    },
    showAddAddressModal() {
      this.closeModal()
      this.$modal.show('add-address-modal', { caller: this.name })
    },
    formatFullAddress(address) {
      return `${formatAddress(address)}, ${address.location.postalCode}`
    },
    selectAddress() {
      this.$emit('change', this.selectedAddress)
      this.closeModal()
    },
    delaySelectAddress() {
      setTimeout(this.selectAddress, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.AddressSelectorModal {
  /deep/ {
    .v--modal {
      width: 100% !important;
      max-width: 400px;
      max-height: 90%;
      top: 50% !important;
      transform: translateY(-50%);

      @media #{$small-only} {
        max-height: 100%;
      }
    }
  }
  .AddressSelectorModalTitle {
    margin-bottom: $space-xxs;
  }
  .AddAddress {
    font-size: $font-size-p;
    padding-bottom: $space-xs;
  }
  .AddressLabel {
    position: relative;
    display: block;
    margin-top: $space-m;
    cursor: pointer;

    .Radio {
      position: absolute;
      top: 10px;
    }
    .RadioLabel {
      display: inline-block;
      padding-left: $space-l;
      line-height: $line-height-p;
    }
    .FullAddress {
      font-size: $font-size-p;
    }
  }
}
</style>
