<template>
  <div class="OrderSummary">
    <time-slots-availability
      :order="order"
      :index="index"/>
    <order-summary-header :order="order"/>
    <order-summary-body :order="order"/>
    <order-summary-footer
      :order="order"
      :index="index"
      :formErrorsVisible="formErrorsVisible"/>
  </div>
</template>

<script>
import OrderSummaryBody from './OrderSummaryBody'
import OrderSummaryFooter from './OrderSummaryFooter'
import OrderSummaryHeader from './OrderSummaryHeader'
import TimeSlotsAvailability from '@/components/TimeSlotsAvailability'

export default {
  name: 'order-summary',
  components: {
    TimeSlotsAvailability,
    OrderSummaryHeader,
    OrderSummaryBody,
    OrderSummaryFooter
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    formErrorsVisible: {
      type: Boolean,
      required: true
    }
  }
}
</script>
