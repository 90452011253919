import { camelizeKeys } from 'humps'

function importScript(src) {
  const script = document.createElement('script')
  script.setAttribute('src', src)
  script.async = true
  document.head.appendChild(script)
}

export function importGooglePlacesScript() {
  if (!window.googlePlacesScriptImported) {
    window.googlePlacesScriptImported = true
    importScript(`https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_PLACES_API_KEY}&libraries=places`)
  }
}

export function searchGooglePlaces(input, callback) {
  // eslint-disable-next-line no-undef
  const service = new google.maps.places.AutocompleteService()
  const request = {
    input,
    componentRestrictions: { country: process.env.VUE_APP_GOOGLE_PLACES_SEARCH_COUNTRY || 'sg' }
  }
  service.getPlacePredictions(request, (predictions, status) => {
    callback(camelizeKeys(predictions), status)
  })
}

export function getGooglePlace(placeId, callback) {
  // eslint-disable-next-line no-undef
  const service = new google.maps.places.PlacesService(document.createElement('div'))
  const request = {
    placeId,
    fields: [
      'address_component',
      'formatted_address',
      'geometry.location',
      'place_id',
      'types'
    ]
  }
  service.getDetails(request, (place, status) => {
    callback(camelizeKeys(place), status)
  })
}
