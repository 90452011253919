<template>
  <div class="OrderInfoForm">
    <div
      v-if="isCnyAlaCarteMenu"
      class="Alert AlertInfo CnyAlaCarteInfo"
      v-html="cnyAlaCarteMenu.terms"/>
    <div
      v-else-if="isChristmasAlaCarteMenu"
      class="Alert AlertInfo ChristmasAlaCarteInfo"
      v-html="christmasAlaCarteMenu.terms"/>
    <div
      v-else-if="isPickupInfoVisible"
      class="Alert AlertInfo"
      v-html="$t('orderInfoForm.pickupInfo')"/>
    <form
      id="OrderInfoForm"
      @submit.prevent="">
      <input
        type="submit"
        class="Hidden">

      <two-columns>
        <template>
          <template v-if="!loggedIn && !notLoggedIn">
            {{ $t('orderInfoForm.loading') }}
          </template>

          <template v-else>
            <template>
              <div class="FormSectionHeader">
                <span class="FormTitle">
                  {{ $t(`orderInfoForm.${isDeliveryEnabled ? 'deliveryInformationTitle' : 'pickupInformationTitle'}`) }}
                </span>
              </div>

              <div
                v-if="!loggedIn && notLoggedIn"
                class="FormInputRow">
                <div class="LoginPrompt">
                  {{ $t('orderInfoForm.loginPrompt') }}
                  <a
                    class="Button--text"
                    @click="showLoginFormModal">
                    {{ $t('orderInfoForm.loginPromptAction') }}<!--
                  -->
                  </a>.
                </div>
                <login-form-modal @login="onLogin"/>
              </div>

              <div
                v-if="loggedIn && !notLoggedIn"
                class="FormInputRow">
                <div class="LoginPrompt">
                  {{ $t('orderInfoForm.logoutPrompt') }}
                  <a
                    class="Button--text"
                    @click="startLogout">
                    {{ $t('orderInfoForm.logoutPromptAction') }}<!--
                  -->
                  </a>.
                </div>
              </div>

              <div class="FormSection">
                <two-columns class="FormInputRow">
                  <template>
                    <label
                      for="customer_name"
                      class="FormLabel">
                      {{ $t('orderInfoForm.customer.nameLabel') }}
                    </label>
                    <input
                      id="customer_name"
                      v-model="customer.name"
                      required
                      type="text"
                      class="FormInput"
                      :class="{'Error':formErrorsVisible && !customer.name}">
                  </template>
                  <template slot="right">
                    <label
                      for="customer_name"
                      class="FormLabel">
                      {{ $t('orderInfoForm.customer.lastNameLabel') }}
                    </label>
                    <input
                      id="customer_last_name"
                      v-model="customer.lastName"
                      required
                      type="text"
                      class="FormInput"
                      :class="{'Error':formErrorsVisible && !customer.lastName}">
                  </template>
                </two-columns>
                <two-columns
                  class="FormInputRow"
                  :leftColumnWidth="70">
                  <template>
                    <label
                      for="customer_email"
                      class="FormLabel">
                      {{ $t('orderInfoForm.customer.emailLabel') }}
                    </label>
                    <div class="EmailInputWrapper">
                      <input
                        id="customer_email"
                        v-model="customer.email"
                        required
                        type="email"
                        autocomplete="email"
                        class="FormInput"
                        :class="{
                          'Error': formErrorsVisible && (!customer.email || emailValidationState === 'invalid'),
                          'InputWithErrorMessage': notLoggedIn && emailValidationState === 'invalid'
                        }"
                        :disabled="loggedIn"
                        @change="validateEmail">
                      <div
                        v-if="notLoggedIn && emailValidationState === 'invalid' && emailValidationError"
                        class="InputErrorMessage">
                        {{ emailValidationError }}
                      </div>
                      <img
                        v-if="notLoggedIn && emailValidationIcon"
                        :src="emailValidationIcon"
                        class="EmailValidationIcon">
                    </div>
                  </template>
                  <template slot="right">
                    <label
                      for="customer_contact_number"
                      class="FormLabel">
                      {{ $t('orderInfoForm.customer.contactNumberLabel') }}
                    </label>
                    <input
                      id="customer_contact_number"
                      v-model="customer.contactNumber"
                      required
                      type="number"
                      class="FormInput"
                      :class="{
                        'Error':formErrorsVisible && (!customer.contactNumber || contactNumberValidationError),
                        'InputWithErrorMessage': contactNumberValidationError
                      }"
                      @change="validateContactNumber(customer.contactNumber)">
                    <div
                      v-if="contactNumberValidationError"
                      class="InputErrorMessage">
                      {{ contactNumberValidationError }}
                    </div>
                  </template>
                </two-columns>
                <two-columns
                  class="FormInputRow"
                  :leftColumnWidth="70">
                  <template>
                    <label
                      for="customer_delivery_address_address"
                      class="FormLabel">
                      {{ $t(`orderInfoForm.address.${isDeliveryEnabled ? 'deliveryAddressLabel' : 'pickupAddressLabel'}`) }}
                    </label>
                    <input
                      v-if="loggedIn || !isDeliveryEnabled"
                      id="customer_delivery_address_address"
                      v-model="customer.deliveryAddress.address"
                      required
                      type="text"
                      class="FormInput"
                      :class="{'Error': formErrorsVisible && (!isDeliveryAddressValid || !customer.deliveryAddress.address)}"
                      :readonly="!isDeliveryEnabled"
                      @click="(loggedIn && isDeliveryEnabled) ? showDeliveryAddressSelectorModal() : null">
                    <component
                      :is="searchAddressComponent"
                      v-else
                      :address="getSelectedDeliveryAddress"
                      :placeholder="$t('orderInfoForm.address.searchAddressPlaceholder')"
                      :inputClass="[
                        'FormInput',
                        formErrorsVisible && (!isDeliveryAddressValid || !customer.deliveryAddress.address) ? 'Error' : ''
                      ].join(' ')"
                      @change="onChangeDeliveryAddress"/>
                    <template v-if="loggedIn">
                      <address-selector-modal
                        name="delivery-address-selector-modal"
                        :addresses="addresses"
                        :selectedAddressId="selectedAddressId"
                        @change="onChangeDeliveryAddress"/>
                      <add-address-modal @reloadAddresses="onReloadAddresses"/>
                    </template>
                  </template>
                  <template slot="right">
                    <label
                      for="customer_delivery_address_postal_code"
                      class="FormLabel">
                      {{ $t('orderInfoForm.address.postalCodeLabel') }}
                    </label>
                    <input
                      v-if="loggedIn || !isDeliveryEnabled"
                      id="customer_delivery_address_postal_code"
                      v-model="customer.deliveryAddress.postalCode"
                      required
                      type="text"
                      class="FormInput"
                      :class="{'Error': formErrorsVisible && !isDeliveryAddressValid}"
                      :readonly="!isDeliveryEnabled"
                      @click="(loggedIn && isDeliveryEnabled) ? showDeliveryAddressSelectorModal() : null">
                    <component
                      :is="searchAddressComponent"
                      v-else
                      type="postal_code"
                      :address="getSelectedDeliveryAddress"
                      placeholder=" "
                      :inputClass="['FormInput', formErrorsVisible && !isDeliveryAddressValid ? 'Error' : ''].join(' ')"
                      @change="onChangeDeliveryAddress"/>
                  </template>
                </two-columns>
                <div
                  v-if="!isDeliveryAddressValid"
                  class="SpecialLocationError">
                  {{ $t('orderInfoForm.specialLocationDeliveryChargeError') }}
                </div>
                <div
                  v-else-if="hasSpecialLocationDeliveryCharge"
                  class="SpecialLocationWarning">
                  {{ $t('orderInfoForm.specialLocationDeliveryChargeWarning') }}
                </div>
                <two-columns
                  class="FormInputRow"
                  :leftColumnWidth="50"
                  :keepColumnWidth="true">
                  <template>
                    <label
                      for="customer_delivery_address_floor"
                      class="FormLabel">
                      {{ $t('orderInfoForm.address.floorLabel') }}
                    </label>
                    <input
                      id="customer_delivery_address_floor"
                      v-model="customer.deliveryAddress.floor"
                      required
                      type="text"
                      class="FormInput"
                      :class="{'Error':formErrorsVisible && !customer.deliveryAddress.floor}"
                      :readonly="!isDeliveryEnabled"
                      @click="(loggedIn && isDeliveryEnabled && !customer.deliveryAddress.address) ? showDeliveryAddressSelectorModal() : null">
                  </template>
                  <template slot="right">
                    <label
                      for="customer_delivery_address_unit_no"
                      class="FormLabel">
                      {{ $t('orderInfoForm.address.unitNoLabel') }}
                    </label>
                    <input
                      id="customer_delivery_address_unit_no"
                      v-model="customer.deliveryAddress.unitNo"
                      required
                      type="text"
                      class="FormInput"
                      :class="{'Error':formErrorsVisible && !customer.deliveryAddress.unitNo}"
                      :readonly="!isDeliveryEnabled"
                      @click="(loggedIn && isDeliveryEnabled && !customer.deliveryAddress.address) ? showDeliveryAddressSelectorModal() : null">
                  </template>
                </two-columns>
                <div class="FormInputRow">
                  <label
                    for="customer_delivery_addess_block_tower"
                    class="FormLabel">
                    {{ $t('orderInfoForm.address.blockTowerLabel') }}
                  </label>
                  <input
                    id="customer_delivery_addess_block_tower"
                    v-model="customer.deliveryAddress.blkTower"
                    type="text"
                    class="FormInput"
                    :readonly="!isDeliveryEnabled"
                    @click="(loggedIn && isDeliveryEnabled) ? showDeliveryAddressSelectorModal() : null">
                </div>

                <div
                  v-if="setupTimes"
                  class="FormInputRow VenueOpenAtSetupTime">
                  <label class="FormLabel">
                    {{ $t('orderInfoForm.venueOpenAtSetupTime', { setupTimes }) }}
                  </label>
                  <label class="FormInputLabel">
                    <input
                      id="venue_open_at_setup_time_yes"
                      v-model="venueOpenAtSetupTime"
                      type="radio"
                      :value="true"
                      name="venue_open_at_setup_time"
                      class="Radio"
                      :class="{'Error': formErrorsVisible && venueOpenAtSetupTime === null}"
                      required>
                    <span>
                      {{ $t('orderInfoForm.venueOpenAtSetupTimeYes') }}
                    </span>
                  </label>
                  <label class="FormInputLabel">
                    <input
                      id="venue_open_at_setup_time_no"
                      v-model="venueOpenAtSetupTime"
                      type="radio"
                      :value="false"
                      name="venue_open_at_setup_time"
                      class="Radio"
                      :class="{'Error': formErrorsVisible && venueOpenAtSetupTime === null}"
                      required>
                    <span>
                      {{ $t('orderInfoForm.venueOpenAtSetupTimeNo') }}
                    </span>
                  </label>
                </div>

                <two-columns
                  class="FormInputRow"
                  :leftColumnWidth="50">
                  <template>
                    <label
                      for="secondary_contact_name"
                      class="FormLabel">
                      {{ $t('orderInfoForm.secondaryContactNameLabel') }}
                    </label>
                    <input
                      id="secondary_contact_name"
                      v-model="secondaryContactName"
                      required
                      type="text"
                      class="FormInput"
                      :class="{'Error': formErrorsVisible && !secondaryContactName}">
                  </template>
                  <template slot="right">
                    <label
                      for="secondary_contact_number"
                      class="FormLabel">
                      {{ $t('orderInfoForm.secondaryContactNumberLabel') }}
                    </label>
                    <input
                      id="secondary_contact_number"
                      v-model="secondaryContactNumber"
                      required
                      type="number"
                      class="FormInput"
                      :class="{
                        'Error': formErrorsVisible && (!secondaryContactNumber || secondaryContactNumberValidationError),
                        'InputWithErrorMessage': secondaryContactNumberValidationError
                      }"
                      @change="validateContactNumber(secondaryContactNumber)">
                    <div
                      v-if="secondaryContactNumberValidationError"
                      class="InputErrorMessage">
                      {{ secondaryContactNumberValidationError }}
                    </div>
                  </template>
                </two-columns>

                <div class="FormInputRow CorporateOrderRow">
                  <label
                    class="FormInputLabel"
                    for="is_corporate_order">
                    <input
                      id="is_corporate_order"
                      v-model="isCorporateOrder"
                      type="checkbox"
                      class="Checkbox">
                    <span>
                      {{ $t('orderInfoForm.corporateOrderLabel') }}
                    </span>
                  </label>
                </div>
                <div
                  v-if="isCorporateOrder"
                  class="FormInputRow">
                  <label
                    for="customer_company_name"
                    class="FormLabel">
                    {{ $t('orderInfoForm.customer.companyNameLabel') }}
                  </label>
                  <input
                    id="customer_company_name"
                    v-model="customer.companyName"
                    required
                    type="text"
                    class="FormInput"
                    :class="{'Error':formErrorsVisible && !customer.companyName}">
                </div>

                <div class="FormInputRow DisposableRequiredRow">
                  <label
                    class="FormLabel"
                    v-html="$t('orderInfoForm.disposablesRequiredLabel')"/>
                  <label
                    class="FormInputLabel"
                    for="is_disposables_required_yes">
                    <input
                      id="is_disposables_required_yes"
                      v-model="isDisposablesRequired"
                      :value="true"
                      name="is_disposables_required"
                      type="radio"
                      class="Radio"
                      :class="{'Error': formErrorsVisible && isDisposablesRequired === null}"
                      required>
                    <span v-html="$t('orderInfoForm.disposablesRequiredYes')"/>
                  </label>
                  <label
                    class="FormInputLabel"
                    for="is_disposables_required_no">
                    <input
                      id="is_disposables_required_no"
                      v-model="isDisposablesRequired"
                      :value="false"
                      name="is_disposables_required"
                      type="radio"
                      class="Radio"
                      :class="{'Error': formErrorsVisible && isDisposablesRequired === null}"
                      required>
                    <span v-html="$t('orderInfoForm.disposablesRequiredNo')"/>
                  </label>
                  <label
                    class="FormLabel DisposablesRequiredNote">
                    <strong>{{ $t('orderInfoForm.disposablesRequiredNote.header') }}</strong> {{ $t('orderInfoForm.disposablesRequiredNote.listItem1') }}
                  </label>
                </div>

                <div class="FormInputRow SpecialInstructionsRow">
                  <label class="FormLabel">
                    {{ $t('orderInfoForm.specialInstructionsLabel') }}
                  </label>
                  <textarea
                    v-model="specialInstructions"
                    class="FormInput"
                    :placeholder="$t('orderInfoForm.specialInstructionsPlaceholder')"
                    rows="2"/>
                </div>
              </div>
            </template>

            <template slot="right">
              <div class="FormSectionHeader">
                <span class="FormTitle">
                  {{ $t('orderInfoForm.billingInformationTitle') }}
                </span>
              </div>

              <div class="FormSection">
                <div class="FormInputRow LoginPrompt">
                  <label
                    class="FormInputLabel"
                    for="billing_address_same_as_delivery_address">
                    <input
                      id="billing_address_same_as_delivery_address"
                      v-model="billingAddressSameAsDeliveryAddress"
                      type="checkbox"
                      class="Checkbox">
                    <span>
                      {{ $t(isDeliveryEnabled ? 'orderInfoForm.billingAddressSameAsDeliveryAddressLabel' : 'orderInfoForm.billingAddressSameAsPickupAddressLabel') }}
                    </span>
                  </label>
                </div>

                <two-columns class="FormInputRow">
                  <template>
                    <label
                      for="customer_billing_address_name"
                      class="FormLabel">
                      {{ $t('orderInfoForm.billingNameLabel') }}
                    </label>
                    <input
                      id="customer_billing_address_name"
                      v-model="customer.billingAddress.name"
                      required
                      type="text"
                      class="FormInput"
                      :class="{'Error': formErrorsVisible && !customer.billingAddress.name}"
                      :readonly="billingAddressSameAsDeliveryAddress">
                  </template>
                  <template slot="right">
                    <label
                      for="customer_billing_address_last_name"
                      class="FormLabel">
                      {{ $t('orderInfoForm.billingLastNameLabel') }}
                    </label>
                    <input
                      id="customer_billing_address_last_name"
                      v-model="customer.billingAddress.lastName"
                      required
                      type="text"
                      class="FormInput"
                      :class="{'Error': formErrorsVisible && !customer.billingAddress.lastName}"
                      :readonly="billingAddressSameAsDeliveryAddress">
                  </template>
                </two-columns>
                <two-columns
                  class="FormInputRow"
                  :leftColumnWidth="70">
                  <template>
                    <label
                      for="customer_billing_address_address"
                      class="FormLabel">
                      {{ $t('orderInfoForm.address.billingAddressLabel') }}
                    </label>
                    <input
                      v-if="loggedIn || billingAddressSameAsDeliveryAddress"
                      id="customer_billing_address_address"
                      v-model="customer.billingAddress.address"
                      required
                      type="text"
                      class="FormInput"
                      :class="{'Error':formErrorsVisible && !customer.billingAddress.address}"
                      :readonly="billingAddressSameAsDeliveryAddress"
                      @click="loggedIn && !billingAddressSameAsDeliveryAddress ? showBillingAddressSelectorModal() : null">
                    <component
                      :is="searchAddressComponent"
                      v-else
                      :address="getSelectedBillingAddress"
                      :placeholder="$t('orderInfoForm.address.searchAddressPlaceholder')"
                      :inputClass="`FormInput${formErrorsVisible && !customer.billingAddress.address ? ' Error' : ''}`"
                      @change="onChangeBillingAddress"/>
                    <template v-if="loggedIn">
                      <address-selector-modal
                        name="billing-address-selector-modal"
                        title="Select billing address"
                        :addresses="addresses"
                        :selectedAddressId="selectedAddressId"
                        @change="onChangeBillingAddress"/>
                    </template>
                  </template>
                  <template slot="right">
                    <label
                      for="customer_billing_address_postal_code"
                      class="FormLabel">
                      {{ $t('orderInfoForm.address.postalCodeLabel') }}
                    </label>
                    <input
                      v-if="loggedIn || billingAddressSameAsDeliveryAddress"
                      id="customer_billing_address_postal_code"
                      v-model="customer.billingAddress.postalCode"
                      required
                      type="text"
                      class="FormInput"
                      :readonly="billingAddressSameAsDeliveryAddress"
                      @click="(loggedIn && !billingAddressSameAsDeliveryAddress) ? showBillingAddressSelectorModal() : null">
                    <component
                      :is="searchAddressComponent"
                      v-else
                      type="postal_code"
                      :address="getSelectedBillingAddress"
                      placeholder=" "
                      inputClass="FormInput"
                      @change="onChangeBillingAddress"/>
                  </template>
                </two-columns>
                <two-columns
                  class="FormInputRow"
                  :leftColumnWidth="50"
                  :keepColumnWidth="true">
                  <template>
                    <label
                      for="customer_billing_address_floor"
                      class="FormLabel">
                      {{ $t('orderInfoForm.address.floorLabel') }}
                    </label>
                    <input
                      id="customer_billing_address_floor"
                      v-model="customer.billingAddress.floor"
                      required
                      type="text"
                      class="FormInput"
                      :class="{'Error':formErrorsVisible && !customer.billingAddress.floor}"
                      :readonly="billingAddressSameAsDeliveryAddress"
                      @click="loggedIn && !billingAddressSameAsDeliveryAddress ? showBillingAddressSelectorModal() : null">
                  </template>
                  <template slot="right">
                    <label
                      for="customer_billing_address_unit_no"
                      class="FormLabel">
                      {{ $t('orderInfoForm.address.unitNoLabel') }}
                    </label>
                    <input
                      id="customer_billing_address_unit_no"
                      v-model="customer.billingAddress.unitNo"
                      required
                      type="text"
                      class="FormInput"
                      :class="{'Error':formErrorsVisible && !customer.billingAddress.unitNo}"
                      :readonly="billingAddressSameAsDeliveryAddress"
                      @click="loggedIn && !billingAddressSameAsDeliveryAddress ? showBillingAddressSelectorModal() : null">
                  </template>
                </two-columns>
                <div class="FormInputRow">
                  <label
                    for="customer_billing_address_block_tower"
                    class="FormLabel">
                    {{ $t('orderInfoForm.address.blockTowerLabel') }}
                  </label>
                  <input
                    id="customer_billing_address_block_tower"
                    v-model="customer.billingAddress.blkTower"
                    type="text"
                    class="FormInput"
                    :readonly="billingAddressSameAsDeliveryAddress"
                    @click="loggedIn && !billingAddressSameAsDeliveryAddress ? showBillingAddressSelectorModal() : null">
                </div>
              </div>

              <div class="Divider"/>

              <div
                v-if="!loggedIn && notLoggedIn"
                class="FormSection CreateAccountSection">
                <div
                  v-if="createAccount"
                  class="FormInputRow">
                  <label
                    for="customer_password"
                    class="FormLabel">
                    {{ $t('orderInfoForm.customer.passwordLabel') }}
                  </label>
                  <input
                    id="customer_password"
                    v-model="password"
                    required
                    type="password"
                    autocomplete="new-password"
                    class="FormInput"
                    :class="{
                      'Error':formErrorsVisible && (!password || passwordValidationError),
                      'InputWithErrorMessage': passwordValidationError
                    }"
                    @change="validatePassword">
                  <div
                    v-if="passwordValidationError"
                    class="InputErrorMessage">
                    {{ passwordValidationError }}
                  </div>
                </div>
              </div>

              <div class="FormSection">
                <div
                  v-if="$slots.default"
                  class="FormInputRow">
                  <slot/>
                </div>
              </div>
            </template>
          </template>
        </template>
      </two-columns>
    </form>
  </div>
</template>

<script>
import UserApi from '@/api/UserApi'
import AddressApi from '@/api/AddressApi'
import { mapGetters } from 'vuex'
import debounce from 'lodash/debounce'
import { scrollWindowToElement } from '@/assets/js/common'
import { formatAddress, sortedAddresses } from '@/assets/js/address-helper'
import { SET_BILLING_ADDRESS_SAME_AS_DELIVERY_ADDRESS, SET_CREATE_ACCOUNT, SET_CUSTOMER, SET_IS_CORPORATE_ORDER, SET_IS_DISPOSABLES_REQUIRED, SET_SECONDARY_CONTACT_NAME, SET_SECONDARY_CONTACT_NUMBER, SET_SELECTED_BILLING_ADDRESS, SET_SELECTED_DELIVERY_ADDRESS, SET_SPECIAL_INSTRUCTIONS, SET_VENUE_OPEN_AT_SETUP_TIME } from '../../actions'
import { getDeliveryFee, getSpecialLocationDeliveryCharge, isDeliveryAddressValid } from '@/assets/js/order-helper'
import { CHRISTMAS_ALA_CARTE_MENU, CNY_ALA_CARTE_MENU } from '@/assets/js/highlight-menu-helper'
import TwoColumns from '@/components/common/TwoColumns'
import LoginFormModal from './LoginFormModal'
import AddressSelectorModal from '@/components/AddressSelectorModal'
import AddAddressModal from '@/components/AddAddressModal'
import SearchAddress from '@/components/SearchAddress'
import SearchGooglePlaces from '@/components/SearchGooglePlaces'

export default {
  name: 'order-info-form',
  components: {
    TwoColumns,
    LoginFormModal,
    AddressSelectorModal,
    AddAddressModal,
    SearchAddress,
    SearchGooglePlaces
  },
  props: {
    formErrorsVisible: {
      type: Boolean,
      required: true
    },
    profile: {
      type: Object,
      default: null
    },
    addresses: {
      type: Array,
      default: null
    },
    selectedAddressId: {
      type: Number,
      default: null
    },
    isProfileLoaded: {
      type: Boolean,
      default: false
    },
    isAddressesLoaded: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      customer: null,
      isDeliveryAddressValid: true,
      hasSpecialLocationDeliveryCharge: false,
      secondaryContactName: null,
      secondaryContactNumber: null,
      billingAddressSameAsDeliveryAddress: null,
      venueOpenAtSetupTime: null,
      isCorporateOrder: null,
      isDisposablesRequired: null,
      createAccount: null,
      specialInstructions: null,
      contactNumberValidationError: null,
      secondaryContactNumberValidationError: null,
      emailValidationDebouncer: debounce(this.checkEmailAvailable, 500),
      emailValidationState: null,
      emailValidationError: null,
      password: null,
      passwordValidationError: null
    }
  },
  computed: {
    ...mapGetters([
      'getOrders',
      'getCustomer',
      'getSelectedDeliveryAddress',
      'getSelectedBillingAddress',
      'getSecondaryContactName',
      'getSecondaryContactNumber',
      'getBillingAddressSameAsDeliveryAddress',
      'getVenueOpenAtSetupTime',
      'getIsCorporateOrder',
      'getIsDisposablesRequired',
      'getCreateAccount',
      'getSpecialInstructions',
      'getPromoCode',
      'isCnyAlaCarteMenu',
      'isChristmasAlaCarteMenu',
      'isFestiveAlaCarteMenu'
    ]),
    googlePlacesEnabled() {
      return process.env.VUE_APP_GOOGLE_PLACES_ENABLED === 'true'
    },
    searchAddressComponent() {
      return this.googlePlacesEnabled ? 'search-google-places' : 'search-address'
    },
    setupTimes() {
      const setupTimes = this.getOrders.map((order) => {
        if (order.menuDetails.eventType !== 'MiniBuffet') {
          const formattedDate = this.$momenttz(order.selectedDate).format('MMM DD')
          const formattedTime = this.$momenttz(order.selectedTime).add(-1, 'hour').format('h:mmA')
          return `${formattedTime} on ${formattedDate}`
        }
        return null
      }).filter((timestamp) => {
        return timestamp !== null
      })
      if (setupTimes.length === 1) {
        return setupTimes[0]
      } else if (setupTimes.length === 2) {
        return setupTimes.join(' and ')
      } else if (setupTimes.length > 2) {
        return `${setupTimes.slice(0, -1).join(', ')}, and ${setupTimes.slice(-1)[0]}`
      }
      return null
    },
    loggedIn() {
      return this.profile && this.isAddressesLoaded
    },
    notLoggedIn() {
      return !this.profile && this.isProfileLoaded
    },
    sortedAddresses() {
      return sortedAddresses(this.addresses, this.selectedAddressId)
    },
    defaultAddress() {
      return this.sortedAddresses[0]
    },
    customerStringified() {
      return JSON.stringify(this.customer)
    },
    emailValidationIcon() {
      switch (this.emailValidationState) {
        case 'valid':
          return require('@/assets/images/icons/icon-success.svg')
        case 'invalid':
          return require('@/assets/images/icons/icon-error.svg')
        case 'validating':
          return require('@/assets/images/icons/icon-loading.svg')
      }
      return null
    },
    isPickupInfoVisible() {
      return this.isDeliveryEnabled && this.getOrders.some(order => order.isSelfPickup)
    },
    isDeliveryEnabled() {
      return !(this.getOrders.every(order => order.isSelfPickup))
    },
    cnyAlaCarteMenu() {
      return CNY_ALA_CARTE_MENU
    },
    christmasAlaCarteMenu() {
      return CHRISTMAS_ALA_CARTE_MENU
    }
  },
  watch: {
    loggedIn: {
      handler() {
        if (this.loggedIn) {
          this.customer = this.getCustomer
          this.customer.name = this.profile.name.split(' ')[0]
          this.customer.lastName = this.profile.name.split(' ').slice(1).join(' ')
          this.customer.contactNumber = this.profile.mobileNumber
          this.customer.email = this.profile.email
        }
      },
      immediate: true
    },
    notLoggedIn() {
      if (this.notLoggedIn) {
        this.validateEmail()
      }
    },
    addresses() {
      if (this.isDeliveryEnabled && this.addresses && this.addresses.length > 0) {
        this.onChangeDeliveryAddress(this.defaultAddress)
        this.onChangeBillingAddress(this.defaultAddress)
      }
    },
    getCustomer() {
      this.customer = this.getCustomer
    },
    getSecondaryContactName() {
      this.secondaryContactName = this.getSecondaryContactName
    },
    getSecondaryContactNumber() {
      this.secondaryContactNumber = this.getSecondaryContactNumber
    },
    getBillingAddressSameAsDeliveryAddress() {
      this.billingAddressSameAsDeliveryAddress = this.getBillingAddressSameAsDeliveryAddress
    },
    getVenueOpenAtSetupTime() {
      this.venueOpenAtSetupTime = this.getVenueOpenAtSetupTime
    },
    getIsCorporateOrder() {
      this.isCorporateOrder = this.getIsCorporateOrder
    },
    getIsDisposablesRequired() {
      this.isDisposablesRequired = this.getIsDisposablesRequired
    },
    getCreateAccount() {
      this.createAccount = this.getCreateAccount
    },
    getSpecialInstructions() {
      this.specialInstructions = this.getSpecialInstructions
    },
    customerStringified() {
      this.$store.dispatch(SET_CUSTOMER, this.customer)
    },
    secondaryContactName() {
      this.$store.dispatch(SET_SECONDARY_CONTACT_NAME, this.secondaryContactName)
    },
    secondaryContactNumber() {
      this.$store.dispatch(SET_SECONDARY_CONTACT_NUMBER, this.secondaryContactNumber)
    },
    billingAddressSameAsDeliveryAddress() {
      this.$store.dispatch(SET_BILLING_ADDRESS_SAME_AS_DELIVERY_ADDRESS, this.billingAddressSameAsDeliveryAddress)
      if (this.billingAddressSameAsDeliveryAddress) {
        this.$store.dispatch(SET_SELECTED_BILLING_ADDRESS, this.getSelectedDeliveryAddress)
        this.customer.billingAddress.name = this.customer.name
        this.customer.billingAddress.lastName = this.customer.lastName
        this.customer.billingAddress.address = this.customer.deliveryAddress.address
        this.customer.billingAddress.postalCode = this.customer.deliveryAddress.postalCode
        this.customer.billingAddress.locationId = this.customer.deliveryAddress.locationId
        this.customer.billingAddress.floor = this.customer.deliveryAddress.floor
        this.customer.billingAddress.unitNo = this.customer.deliveryAddress.unitNo
        this.customer.billingAddress.blkTower = this.customer.deliveryAddress.blkTower
      }
    },
    venueOpenAtSetupTime() {
      this.$store.dispatch(SET_VENUE_OPEN_AT_SETUP_TIME, this.venueOpenAtSetupTime)
    },
    isCorporateOrder() {
      this.$store.dispatch(SET_IS_CORPORATE_ORDER, this.isCorporateOrder)
    },
    isDisposablesRequired() {
      this.$store.dispatch(SET_IS_DISPOSABLES_REQUIRED, this.isDisposablesRequired)
    },
    createAccount() {
      this.$store.dispatch(SET_CREATE_ACCOUNT, this.createAccount)
    },
    specialInstructions() {
      this.$store.dispatch(SET_SPECIAL_INSTRUCTIONS, this.specialInstructions)
    },
    getSelectedDeliveryAddress() {
      if (this.billingAddressSameAsDeliveryAddress) {
        this.$store.dispatch(SET_SELECTED_BILLING_ADDRESS, this.getSelectedDeliveryAddress)
      }
    },
    getSelectedBillingAddress() {
      if (this.loggedIn && this.getSelectedBillingAddress.companyName) {
        this.customer.companyName = this.getSelectedBillingAddress.companyName
      }
    },
    password() {
      this.$emit('changePassword', this.password)
      if (this.passwordValidationError) {
        this.validatePassword()
      }
    },
    'customer.name'() {
      if (this.billingAddressSameAsDeliveryAddress) {
        this.customer.billingAddress.name = this.customer.name
      }
    },
    'customer.lastName'() {
      if (this.billingAddressSameAsDeliveryAddress) {
        this.customer.billingAddress.lastName = this.customer.lastName
      }
    },
    'customer.deliveryAddress.address'() {
      if (this.billingAddressSameAsDeliveryAddress) {
        this.customer.billingAddress.address = this.customer.deliveryAddress.address
      }
    },
    'customer.deliveryAddress.postalCode'() {
      if (this.billingAddressSameAsDeliveryAddress) {
        this.customer.billingAddress.postalCode = this.customer.deliveryAddress.postalCode
      }
      if (this.customer.deliveryAddress.postalCode) {
        this.isDeliveryAddressValid = isDeliveryAddressValid(this.customer)
        this.hasSpecialLocationDeliveryCharge = this.getOrders.some(order => {
          const specialDeliveryCharge = getSpecialLocationDeliveryCharge(order, this.customer)
          if (specialDeliveryCharge > 0) {
            const deliveryFee = getDeliveryFee(order, null, null, this.customer)
            return specialDeliveryCharge === deliveryFee
          }
          return false
        })
      }
    },
    'customer.deliveryAddress.locationId'() {
      if (this.billingAddressSameAsDeliveryAddress) {
        this.customer.billingAddress.locationId = this.customer.deliveryAddress.locationId
      }
    },
    'customer.deliveryAddress.floor'() {
      if (this.billingAddressSameAsDeliveryAddress) {
        this.customer.billingAddress.floor = this.customer.deliveryAddress.floor
      }
    },
    'customer.deliveryAddress.unitNo'() {
      if (this.billingAddressSameAsDeliveryAddress) {
        this.customer.billingAddress.unitNo = this.customer.deliveryAddress.unitNo
      }
    },
    'customer.deliveryAddress.blkTower'() {
      if (this.billingAddressSameAsDeliveryAddress) {
        this.customer.billingAddress.blkTower = this.customer.deliveryAddress.blkTower
      }
    },
    'customer.email'() {
      this.validateEmail()
    }
  },
  created() {
    this.customer = this.getCustomer
    this.secondaryContactName = this.getSecondaryContactName
    this.secondaryContactNumber = this.getSecondaryContactNumber
    this.billingAddressSameAsDeliveryAddress = this.isDeliveryEnabled ? this.getBillingAddressSameAsDeliveryAddress : false
    this.venueOpenAtSetupTime = this.getVenueOpenAtSetupTime
    this.isCorporateOrder = this.getIsCorporateOrder
    this.isDisposablesRequired = this.getIsDisposablesRequired
    this.createAccount = this.getCreateAccount
    this.specialInstructions = this.getSpecialInstructions

    this.validateEmail()

    if (!this.isDeliveryEnabled) {
      this.searchForGrain()
    }
  },
  methods: {
    showLoginFormModal() {
      this.$modal.show('login-form-modal')
    },
    onLogin(token) {
      this.$emit('login', token)
    },
    startLogout() {
      this.$emit('logout')
    },
    formatAddress(address) {
      return formatAddress(address)
    },
    showDeliveryAddressSelectorModal() {
      this.$modal.show('delivery-address-selector-modal')
    },
    showBillingAddressSelectorModal() {
      this.$modal.show('billing-address-selector-modal')
    },
    validateContactNumber(contactNumber) {
      let validationError
      if (contactNumber.length === 8 && (contactNumber[0] === '8' || contactNumber[0] === '9')) {
        validationError = null
      } else {
        validationError = this.$t('orderInfoForm.contactNumberInvalidError')
      }
      switch (contactNumber) {
        case this.customer.contactNumber:
          this.contactNumberValidationError = validationError
          break
        case this.secondaryContactNumber:
          this.secondaryContactNumberValidationError = validationError
          break
      }
      return !validationError
    },
    validateEmail() {
      if (this.notLoggedIn && this.customer.email) {
        const EMAIL_VALIDATION_REGEX = /\S+@\S+\./
        if (EMAIL_VALIDATION_REGEX.test(this.customer.email)) {
          this.emailValidationState = 'validating'
          this.emailValidationError = null
          this.emailValidationDebouncer.cancel()
          this.emailValidationDebouncer()
        } else {
          this.emailValidationState = 'invalid'
          this.emailValidationError = this.$t('orderInfoForm.emailFormatError')
        }
      } else {
        this.emailValidationState = null
        this.emailValidationError = null
      }
    },
    async checkEmailAvailable() {
      const result = (await UserApi.checkEmailAvailable(this.customer.email))
      if (result.available) {
        this.emailValidationState = 'valid'
        this.emailValidationError = null
      } else {
        this.emailValidationState = 'invalid'
        this.emailValidationError = this.$t('orderInfoForm.emailUnavailableError')
      }
    },
    validatePassword() {
      if (this.createAccount && !this.password) {
        this.passwordValidationError = this.$t('orderInfoForm.passwordMissingError')
      } else if (this.createAccount && this.password.length < 6) {
        this.passwordValidationError = this.$t('orderInfoForm.passwordLengthError')
      } else {
        this.passwordValidationError = null
      }
    },
    onChangeDeliveryAddress(address, isSearchedAddress = false) {
      if (!address) return

      if (this.loggedIn && !isSearchedAddress) {
        this.customer.deliveryAddress.address = address.location.fullAddress || address.location.address
        this.customer.deliveryAddress.postalCode = address.location.postalCode
        this.customer.deliveryAddress.locationId = address.location.id
        this.customer.deliveryAddress.floor = address.floor
        this.customer.deliveryAddress.unitNo = address.unitNo
        this.customer.deliveryAddress.blkTower = address.blkTower

        this.$store.dispatch(SET_SELECTED_DELIVERY_ADDRESS, {
          address: this.customer.deliveryAddress.address,
          postalCode: this.customer.deliveryAddress.postalCode,
          companyName: address.companyName
        })

        this.$nextTick(() => {
          if (this.getSelectedBillingAddress && this.getSelectedBillingAddress.companyName) {
            this.customer.companyName = this.getSelectedBillingAddress.companyName
          } else if (address.companyName) {
            this.customer.companyName = address.companyName
          }
        })
      } else if (this.notLoggedIn || isSearchedAddress) {
        this.$store.dispatch(SET_SELECTED_DELIVERY_ADDRESS, address)
        this.customer.deliveryAddress.address = address.fullAddress || address.address
        this.customer.deliveryAddress.postalCode = address.postalCode
        this.customer.deliveryAddress.locationId = address.id
        if (isSearchedAddress) {
          this.customer.deliveryAddress.floor = address.floor
          this.customer.deliveryAddress.unitNo = address.unitNo
          this.customer.deliveryAddress.blkTower = address.blkTower
        }
        this.$store.dispatch(SET_CUSTOMER, this.customer)
      }
    },
    onChangeBillingAddress(address) {
      if (!address) return

      if (this.loggedIn) {
        this.customer.billingAddress.address = address.location.fullAddress || address.location.address
        this.customer.billingAddress.postalCode = address.location.postalCode
        this.customer.billingAddress.floor = address.floor
        this.customer.billingAddress.unitNo = address.unitNo
        this.customer.billingAddress.blkTower = address.blkTower

        this.$store.dispatch(SET_SELECTED_BILLING_ADDRESS, {
          address: this.customer.billingAddress.address,
          postalCode: this.customer.billingAddress.postalCode,
          companyName: address.companyName
        })
      } else if (this.notLoggedIn) {
        this.$store.dispatch(SET_SELECTED_BILLING_ADDRESS, address)
        this.customer.billingAddress.address = address.fullAddress || address.address
        this.customer.billingAddress.postalCode = address.postalCode
        this.$store.dispatch(SET_CUSTOMER, this.customer)
      }
    },
    onReloadAddresses(addresses, selectedAddressId, caller) {
      this.$emit('reloadAddresses', addresses, selectedAddressId)
      this.$nextTick(() => {
        if (caller === 'delivery-address-selector-modal') {
          this.onChangeDeliveryAddress(this.defaultAddress)
        } else if (caller === 'billing-address-selector-modal') {
          this.onChangeBillingAddress(this.defaultAddress)
        }
      })
    },
    // this is used by CheckoutPage
    checkValidations() {
      let valid = true
      if (!this.customer.deliveryAddress.address) {
        const deliveryAddressLabel = this.$el.querySelector("label[for='customer_delivery_address_address']")
        scrollWindowToElement(deliveryAddressLabel, 0, 0)
        valid = false
      } else if (!this.customer.billingAddress.address) {
        const billingAddressLabel = this.$el.querySelector("label[for='customer_billing_address_address']")
        scrollWindowToElement(billingAddressLabel, 0, 0)
        valid = false
      }
      if (this.notLoggedIn) {
        if (this.emailValidationState !== 'valid') {
          const emailField = this.$el.querySelector("input[type='email']")
          scrollWindowToElement(emailField, 0, 0)
          valid = false
        } else if (this.createAccount && this.passwordValidationError) {
          const passwordField = this.$el.querySelector("input[type='password']")
          scrollWindowToElement(passwordField, 0, 0)
          valid = false
        }
      }
      if (!this.validateContactNumber(this.customer.contactNumber)) {
        const contactNumberField = this.$el.querySelector('#customer_contact_number')
        scrollWindowToElement(contactNumberField, 0, 0)
        valid = false
      }
      if (!this.validateContactNumber(this.secondaryContactNumber)) {
        const secondaryContactNumberField = this.$el.querySelector('#secondary_contact_number')
        scrollWindowToElement(secondaryContactNumberField, 0, 0)
        valid = false
      }
      return valid
    },
    async searchForGrain() {
      const searchResult = (await AddressApi.fetchAddress('369972', 1))
      this.onChangeDeliveryAddress(Object.assign({
        floor: '05',
        unitNo: '01',
        blkTower: ''
      }, searchResult.items[0]), true)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/form-input";

.OrderInfoForm {
  margin: $space-m 0 0;

  .CnyAlaCarteInfo,
  .ChristmasAlaCarteInfo {
    /deep/ > span {
      white-space: nowrap;
    }
  }
  #OrderInfoForm /deep/ > .TwoColumns {
    margin-left: 0;
    margin-right: 0;

    > .GridColumn {
      padding-left: $space-l;
      padding-right: $space-l;

      &:first-child {
        padding-left: 0;

        @media #{$tablet-down} {
          padding-right: 0;
        }
      }
      &:last-child {
        padding-right: 0;
        border-left: 1px solid $gray-lighter;

        @media #{$tablet-down} {
          padding-left: 0;
          border-left: 0;
        }
      }
      &:not(:last-child) {
        @media #{$tablet-down} {
          margin-bottom: $space-xl;
        }
      }
    }
  }
  /deep/ > .GridRow {
    margin-left: -$space-m;
    margin-right: -$space-m;

    > .GridColumn {
      padding-left: $space-m;
      padding-right: $space-m;

      &:not(:last-child) {
        @media (max-width: 640px) {
          margin-bottom: $space-m;
        }
      }
    }
  }
  .FormSectionHeader {
    margin-bottom: $space-xs;

    &.SpecialInstructionsHeader {
      margin-top: $space-xl;
    }
  }
  .FormTitle {
    margin-right: $space-m;
    font-size: 18px;
    font-weight: bold;
    color: $ink;

    @media (max-width: 640px) {
      display: block;
      margin-right: 0;
      margin-bottom: $space-xxs;
    }
  }
  .LoginPrompt {
    margin-bottom: $space-s;
    @extend %body;
    color: $gray-darker;
  }
  .FormByline {
    margin-top: $space-xxs;
    @extend %body;
  }
  .FormSection {
    &:not(:last-child) {
      margin-bottom: $space-m;
    }
    &:last-child {
      .FormInputRow:last-child {
        .FormInputLabel:last-child {
          margin-bottom: 0;
        }
      }
    }
    &.CreateAccountSection {
      margin-top: $space-xl;
    }
    .FormInputRow {
      &:last-child {
        input, textarea {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      &.VenueOpenAtSetupTime {
        margin-bottom: $space-xs;

        .FormInputLabel {
          display: inline-block;
          margin-top: $space-xs;
          margin-right: $space-m;
        }
      }
      &.CorporateOrderRow {
        + .DisposableRequiredRow {
          margin-top: $space-l;
        }
      }
      &.DisposableRequiredRow {
        + .SpecialInstructionsRow {
          margin-top: $space-l;
        }

        > .FormLabel {
          margin-bottom: $space-s;
        }

        .DisposablesRequiredNote {
          margin-top: $space-s;

          > ol {
            margin-top: $space-s;
            margin-bottom: $space-s;
            padding-left: $space-m;

            > li:not(:first-child) {
              margin-top: $space-s;
            }
          }
        }
      }
      /deep/ .ResultsWrapper {
        .ResultOption.FormInput {
          @extend %body;
        }
        .ResultOption.Hover .ResultOptionColumnRight > * {
          background-color: black;
        }
      }
    }
    /deep/ .TwoColumns.FormInputRow {
      margin-left: -$space-xxs;
      margin-right: -$space-xxs;

      > .GridColumn {
        padding-left: $space-xxs;
        padding-right: $space-xxs;
      }
    }
    .EmailInputWrapper {
      position: relative;

      .EmailValidationIcon {
        position: absolute;
        width: 24px;
        top: 10px;
        right: $space-xs;
      }
    }
    .SpecialLocationError,
    .SpecialLocationWarning {
      margin-top: -$space-m;
      margin-bottom: 1.25rem;
      @extend %small;
    }
    .SpecialLocationError {
      color: $red;
    }
    .SpecialLocationWarning {
      color: $orange;
    }
  }
}
</style>
