<template>
  <base-page
    shoutType="catering"
    class="CheckoutPage">
    <div class="Container">
      <div class="CheckoutHeader">
        <img
          :src="logotype"
          class="Logotype">
        <progress-tracker :currentStep="3"/>
      </div>
      <div class="CheckoutBody">
        <div
          class="OrderSummaries"
          :class="{'MultipleOrders': getOrders.length > 1}">
          <order-summary
            v-for="(order, index) in getOrders"
            :key="`order-summary-${index}`"
            ref="OrderSummary"
            :order="order"
            :index="index"
            :formErrorsVisible="formErrorsVisible"/>
        </div>

        <div class="promo-code">
          <two-columns>
            <template slot="right">
              <div class="promo-code-wrapper">
                <div class="promo-code-inner-wrapper">
                  <input
                    id="order_promo_code"
                    v-model="promoCode"
                    type="text"
                    :placeholder="$t('order.haveAPromoCodePlaceholder')"
                    name="order_promo_code"
                    class="FormInput"
                    :size="promoCode && promoCode.length * 1.3"
                    :class="{
                      'Error': formErrorsVisible && promoCodeValidationError,
                      'InputWithErrorMessage': promoCodeValidationError,
                      'applied': promoCodeApplied
                    }"
                    @keyup.enter="initialPromoCodeValidation">
                  <span
                    v-if="promoCode"
                    class="ModalClose remove-promo-code"
                    :class="{'applied': promoCodeApplied}"
                    @click="clearPromoCode">
                    ✕
                  </span>
                </div>
                <span
                  v-if="promoCodeApplied && promotionDiscount">
                  - {{ $t('common.price', { price: promotionDiscount }) }}
                </span>
                <span
                  v-else-if="promoCodeApplied && promotionFreeItem">
                  {{ $t('order.free') }}
                </span>
                <span
                  v-else-if="!promoCodeApplied"
                  :disabled="!promoCode"
                  class="Button--text Button--text-primary"
                  @click="initialPromoCodeValidation">
                  {{ $t('order.apply') }}
                </span>
              </div>
              <div
                v-if="promoCodeValidationError"
                class="InputErrorMessage">
                {{ promoCodeValidationError }}
              </div>
              <span
                v-else-if="promoCodeApplied && promotionFreeItem"
                class="free-item-text">
                {{ $t('order.freePromotionItem', { item: promotionFreeItem }) }}
              </span>
            </template>
          </two-columns>
        </div>

        <div class="OrdersTabulation">
          <two-columns>
            <template slot="right">
              <orders-total
                :total="total"
                :totalWithGst="totalWithGst"
                :checkout="true"/>
            </template>
          </two-columns>
        </div>
        <div class="Divider"/>
        <order-info-form
          v-if="isLoggedIn !== null"
          ref="CheckoutForm"
          :formErrorsVisible="formErrorsVisible"
          :profile="profile"
          :addresses="addresses"
          :selectedAddressId="selectedAddressId"
          :isProfileLoaded="isProfileLoaded"
          :isAddressesLoaded="isAddressesLoaded"
          @login="onLogin"
          @logout="onLogout"
          @changePassword="onChangePassword"
          @reloadAddresses="onReloadAddresses">
          <template v-if="payOnCheckout">
            <div class="Payment">
              <h4>
                {{ $t('checkout.paymentLabel') }}
              </h4>
              <label for="payment_credit_card">
                <input
                  id="payment_credit_card"
                  v-model="paymentMethod"
                  type="radio"
                  :value="PAYMENT_CARD"
                  name="payment"
                  class="Radio">
                <span>
                  {{ $t('checkout.payWithCardLabel') }}
                </span>
              </label>
              <label for="payment_others">
                <input
                  id="payment_others"
                  v-model="paymentMethod"
                  type="radio"
                  :value="PAYMENT_OTHERS"
                  name="payment"
                  class="Radio">
                <span>
                  {{ $t('checkout.payWithOtherMethodLabel') }}
                </span>
              </label>
              <payment-wallet
                v-if="isFeaturesLoaded && isLoggedIn !== null"
                :class="{'Disabled': paymentMethod !== PAYMENT_CARD}"
                :stripeKey="stripeKey"
                :paymentAmount="totalWithGstInCents"
                :shippingOptions="getShippingOptions"
                :paymentButtonTitle="paymentButtonTitle"
                :error="processingError"
                :guestCheckout="!isLoggedIn"
                :createGuestAccount="getCreateAccount"
                @startStripePayment="startStripePayment"
                @endStripePayment="endStripePayment"
                @paymentRequestCompleteMethod="setPaymentRequestCompleteMethod"
                @makeStripePayment="makeStripePayment"/>
              <template v-if="paymentMethod === PAYMENT_OTHERS">
                <div
                  v-if="processingError"
                  class="CheckoutError">
                  {{ processingError }}
                </div>
                <div
                  class="Button Button--primary Button--full PlaceOrderButton"
                  @click="startPlaceOrder">
                  {{ paymentButtonTitle }}
                </div>
              </template>
            </div>
          </template>
          <template v-else-if="isLoggedIn !== null && (loggedIn || notLoggedIn)">
            <div
              v-if="processingError"
              class="CheckoutError">
              {{ processingError }}
            </div>
            <div
              class="Button Button--primary Button--full PlaceOrderButton"
              @click="startPlaceOrder">
              {{ paymentButtonTitle }}
            </div>
          </template>
        </order-info-form>
      </div>
    </div>
    <location-configs/>
    <loading-overlay v-if="loadingOverlayVisible"/>
  </base-page>
</template>

<script>
import * as Sentry from '@sentry/browser'
import { SET_CHECKOUT_COMPLETE, SET_CHECKOUT_ERRORS_VISIBLE, SET_ORDER_PAID, SET_PROMO_CODE, SET_SALES_ORDER } from '../actions'
import { getCostWithGst, getCostWithoutGst, getDeliveryFee, getStairsCost, getSubtotal, getTotal, isDeliveryAddressValid, isOrderValid } from '@/assets/js/order-helper'
import AddressApi from '@/api/AddressApi'
import BasePage from '@/components/common/BasePage'
import LoadingOverlay from '@/components/LoadingOverlay'
import LocationConfigs from '@/components/LocationConfigs'
import OrderInfoForm from '@/components/OrderInfoForm'
import OrderSummary from '@/components/OrderSummary'
import OrdersApi from '@/api/OrdersApi'
import OrdersTotal from '@/components/OrdersTotal'
import PaymentWallet from '@/modules/PaymentWallet'
import ProgressTracker from '@/components/ProgressTracker'
import TwoColumns from '@/components/common/TwoColumns'
import UserApi from '@/api/UserApi'
import cloneDeep from 'lodash/cloneDeep'
import cookies from '@/assets/js/cookies'
import { mapGetters } from 'vuex'
import paymentRequestMixin from '@/mixins/payment-request-mixin'

const PAYMENT_CARD = 'card'
const PAYMENT_OTHERS = 'others'

export default {
  name: 'checkout-page',
  metaInfo: {
    title: 'Grain | Food experience company in Singapore',
    meta: [
      { name: 'robots', content: 'none' }
    ]
  },
  components: {
    BasePage,
    ProgressTracker,
    OrderSummary,
    TwoColumns,
    OrdersTotal,
    OrderInfoForm,
    PaymentWallet,
    LocationConfigs,
    LoadingOverlay
  },
  mixins: [paymentRequestMixin],
  data() {
    return {
      PAYMENT_CARD,
      PAYMENT_OTHERS,
      payOnCheckout: false,
      paymentMethod: 'card',
      isFeaturesLoaded: false,
      isLoggedIn: null,
      isProfileLoaded: false,
      isAddressesLoaded: false,
      profile: null,
      addresses: [],
      selectedAddressId: null,
      loadingOverlayVisible: false,
      processingError: null,
      formErrorsVisible: false,
      password: null,
      promoCode: null,
      promoCodeValidationError: null,
      promotionDiscount: null,
      promotionFreeItem: null
    }
  },
  computed: {
    ...mapGetters([
      'getOrders',
      'getReadyForCheckout',
      'getCustomer',
      'getSecondaryContactName',
      'getSecondaryContactNumber',
      'getVenueOpenAtSetupTime',
      'getIsCorporateOrder',
      'getIsDisposablesRequired',
      'getCreateAccount',
      'getSpecialInstructions',
      'getPromoCode'
    ]),
    logotype() {
      return require('@/assets/images/grain-logotype.png')
    },
    paymentButtonTitle() {
      return `${this.$t('checkout.placeOrderButtonTitle')} - ${this.$t('common.price', { price: this.totalWithGst })}`
    },
    loggedIn() {
      return this.profile && this.isAddressesLoaded
    },
    notLoggedIn() {
      return !this.profile && this.isProfileLoaded
    },
    products() {
      return this.getOrders.map((order) => {
        const pricePerPax = parseFloat(order.selectedMenuPricing.pricePerPax).toFixed(2)
        return {
          product_id: order.menuDetails.id,
          name: order.menuDetails.name,
          variant: `${this.$t('common.price', { price: pricePerPax })}/guest`,
          price: getSubtotal(order)
        }
      })
    },
    total() {
      return getTotal(this.getOrders, this.promotionDiscount, this.getCustomer)
    },
    preDiscountTotalWithGst() {
      // For use in validation
      return this.getOrders.reduce((accumulator, order) => {
        return accumulator + parseFloat(getCostWithGst(parseFloat(getTotal([order], null, this.getCustomer)), order.selectedDate))
      }, 0).toFixed(2)
    },
    totalWithGst() {
      return parseFloat(getCostWithGst(this.total, this.getOrders[0].selectedDate)).toFixed(2)
    },
    totalTax() {
      return parseFloat((parseFloat(this.totalWithGst) - parseFloat(this.total)).toFixed(2))
    },
    totalShipping() {
      return this.getOrders.reduce((accumulator, order) => {
        return accumulator + getDeliveryFee(order, null, null, this.getCustomer) + getStairsCost(order)
      }, 0)
    },
    totalRevenue() {
      return parseFloat(this.totalWithGst) - this.totalTax - this.totalShipping
    },
    totalWithGstInCents() {
      return parseInt(parseFloat(this.totalWithGst) * 100)
    },
    stripeKey() {
      return process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
    },
    getShippingOptions() {
      return {
        id: 'delivery',
        label: 'delivery',
        detail: 'delivery',
        amount: this.totalShipping * 100
      }
    },
    formPayload() {
      return {
        promoCode: this.promoCode,
        customer: this.getCustomer,
        secondaryContactName: this.getSecondaryContactName,
        secondaryContactNumber: this.getSecondaryContactNumber,
        venueOpenAtSetupTime: this.getVenueOpenAtSetupTime,
        isCorporateOrder: this.getIsCorporateOrder,
        isDisposablesRequired: this.getIsDisposablesRequired,
        createAccount: this.getCreateAccount,
        specialInstructions: this.getSpecialInstructions,
        referredBy: cookies.getCookie('referred_by')
      }
    },
    promoCodeApplied() {
      return this.promoCode && !this.promoCodeValidationError && (this.promotionDiscount || this.promotionFreeItem)
    }
  },
  watch: {
    getPromoCode() {
      this.promoCode = this.getPromoCode
    },
    promoCode() {
      this.$store.dispatch(SET_PROMO_CODE, this.promoCode)
    }
  },
  created() {
    this.promoCode = this.getPromoCode
    if (this.promoCode) this.initialPromoCodeValidation()
    if (!this.getReadyForCheckout) {
      this.$router.replace('/catering/select-menu-items')
    } else {
      window.scroll(0, 0)
      this.fetchFeatures()
      if (cookies.getCookie('external_access_token')) {
        this.fetchProfile()
        this.fetchAddresses()
      } else {
        this.isLoggedIn = false
        this.isProfileLoaded = true
        this.isAddressesLoaded = true
      }

      window.analytics.track('Cart Viewed', {
        products: this.products
      })
      this.$nextTick(() => {
        window.analytics.track('Checkout Started', {
          products: this.products,
          value: parseFloat(this.totalWithGst),
          tax: this.totalTax,
          shipping: this.totalShipping,
          revenue: this.totalRevenue
        })
      })
    }
  },
  methods: {
    async fetchFeatures() {
      try {
        const result = (await UserApi.fetchFeatures())
        this.payOnCheckout = !!result.features.cateringSelfCheckout
        this.isFeaturesLoaded = true
      } catch(e) {
        this.isFeaturesLoaded = true
      }
    },
    async fetchProfile() {
      try {
        const result = (await UserApi.fetchProfile())
        this.profile = result.profile
        this.isLoggedIn = true
        this.isProfileLoaded = true
      } catch(e) {
        this.isLoggedIn = false
        this.isProfileLoaded = true
      }
    },
    async fetchAddresses() {
      try {
        const result = (await AddressApi.fetchAddresses())
        this.addresses = result.addresses
        this.selectedAddressId = result.selectedAddressId
        this.isLoggedIn = true
        this.isAddressesLoaded = true
      } catch(e) {
        this.isLoggedIn = false
        this.isAddressesLoaded = true
      }
    },
    onLogin(token) {
      cookies.setCookie('external_access_token', token)
      this.isLoggedIn = null
      this.fetchProfile()
      this.fetchAddresses()
    },
    async onLogout() {
      this.loadingOverlayVisible = true
      try {
        this.isLoggedIn = null
        await UserApi.logout()
        cookies.deleteCookie('access_level')
        cookies.deleteCookie('external_access_token')
        cookies.deleteCookie('session_user_uuid')
        this.profile = null
        this.addresses = []
        this.selectedAddressId = null
        this.isLoggedIn = false
      } catch(e) {
        this.$modal.show('common-modal', {
          title: 'Error',
          message: 'An unexpected error occurred while attempting to log out. Please try again later.'
        })
      }
      this.loadingOverlayVisible = false
    },
    onChangePassword(password) {
      this.password = password
    },
    onReloadAddresses(addresses, selectedAddressId) {
      this.addresses = addresses
      this.selectedAddressId = selectedAddressId
    },
    trackCheckout(event) {
      window.analytics.track(event, {
        products: this.products,
        value: parseFloat(this.totalWithGst),
        tax: this.totalTax,
        shipping: this.totalShipping,
        revenue: this.totalRevenue
      })
    },
    validateOrderInfoForm() {
      if (!this.validateOrderSummaryForms()) return false
      const vueOrderInfoForm = this.$refs['CheckoutForm']
      const orderInfoForm = vueOrderInfoForm.$el.querySelector('form')
      const orderInfoFormValid = orderInfoForm.checkValidity()
      if (isDeliveryAddressValid(this.getCustomer) && orderInfoFormValid && vueOrderInfoForm.checkValidations()) {
        return true
      } else {
        this.loadingOverlayVisible = false
        if (!orderInfoFormValid) {
          orderInfoForm.querySelector("input[type='submit']").click()
        }
        this.formErrorsVisible = true
        return false
      }
    },
    validateOrderSummaryForms() {
      const orderSummaries = this.$refs['OrderSummary'] || []
      return orderSummaries.every((orderSummary) => {
        const orderSummaryForm = orderSummary.$el.querySelector('.OrderSummaryFooter form')
        if (!orderSummaryForm) return true // Bypass because buffet to go does not have form fields in OrderSummaryFooter

        const orderSummaryFormValid = orderSummaryForm.checkValidity()
        if (!orderSummaryFormValid) {
          this.loadingOverlayVisible = false
          orderSummaryForm.querySelector("input[type='submit']").click()
          this.formErrorsVisible = true
        }
        return orderSummaryFormValid
      })
    },
    startPlaceOrder() {
      this.loadingOverlayVisible = true
      this.processingError = null
      if (this.validateOrders()) {
        if (this.validateOrderInfoForm()) {
          const payload = Object.assign({}, this.formPayload)
          payload.customer.password = this.password
          payload.chargeAmount = this.totalWithGstInCents
          if (this.profile) {
            payload.userId = this.profile.id
          }
          this.placeOrder(payload)
        }
      } else {
        this.$store.dispatch(SET_CHECKOUT_ERRORS_VISIBLE, true)
        this.$router.push('/catering/select-menu-items')
      }
    },
    validateOrders() {
      let validOrder = true
      this.getOrders.forEach(order => {
        if (!isOrderValid(order)) {
          validOrder = false
        }
      })
      return validOrder
    },
    async placeOrder(payload) {
      try {
        const ordersClone = this.getOrders.map((order) => Object.assign({}, order))
        ordersClone.forEach((order) => {
          if (order.isSelfPickup) {
            order.selectedTime = this.$momenttz(order.selectedTime).add(1, 'hour').add(15, 'minutes').toDate().toISOString()
          }
        })
        payload.total = this.preDiscountTotalWithGst // used to validate promo code on checkout
        const result = (await OrdersApi.createOrders(ordersClone, payload))
        if (result.accessToken) {
          cookies.setCookie('external_access_token', result.accessToken.token || result.accessToken.uid)
        }
        this.completePaymentRequest('success')
        this.$store.dispatch(SET_CHECKOUT_COMPLETE, true)
        this.$store.dispatch(SET_SALES_ORDER, result.salesOrder)
        this.$store.dispatch(SET_ORDER_PAID, result.paid)
        this.trackOrderComplete(result)
        if (result.isNewCustomer) {
          this.$router.push('/catering/welcome-success')
        } else {
          this.$router.push('/catering/success')
        }
      } catch(e) {
        this.loadingOverlayVisible = false
        this.completePaymentRequest('fail')
        this.processingError = e || this.$t('checkout.unexpectedError')
        try {
          Sentry.captureMessage(e)
        } catch(_e) {
          // throw the original error
          throw e
        }
      }
    },
    startStripePayment() {
      this.loadingOverlayVisible = true
      this.processingError = null
    },
    endStripePayment(payload) {
      this.loadingOverlayVisible = false
      this.processingError = payload.error.message
      Sentry.captureMessage(payload.error.message)
      Sentry.captureMessage(payload.error)
    },
    makeStripePayment(payload) {
      if (this.validateOrders()) {
        if (this.validateOrderInfoForm()) {
          const finalPayload = Object.assign({}, this.formPayload)
          finalPayload.customer.password = this.password
          if (payload.token) {
            finalPayload.token = payload.token.id
          } else if (payload.card) {
            finalPayload.card = payload.card.id
          }
          if (payload.setAsDefault) {
            finalPayload.setAsDefault = payload.setAsDefault
          }
          finalPayload.makePayment = true
          finalPayload.chargeAmount = this.totalWithGstInCents
          if (this.profile) {
            finalPayload.userId = this.profile.id
          }
          this.placeOrder(finalPayload)
        }
      } else {
        this.$store.dispatch(SET_CHECKOUT_ERRORS_VISIBLE, true)
        this.$router.push('/catering/select-menu-items')
      }
    },
    trackOrderComplete(result) {
      try {
        window.analytics.track('Order Completed', {
          order_id: result.salesOrder ? result.salesOrder.orderId : null,
          products: this.products,
          total: parseFloat(this.totalWithGst),
          tax: this.totalTax,
          shipping: this.totalShipping,
          revenue: this.totalRevenue,
          currency: 'SGD'
        })
      } catch(e) {}
    },
    async initialPromoCodeValidation(e) {
      if (e) e.target.blur() // blurs focus when enter key is used to apply promo
      if (!this.promoCode) return

      try {
        const ordersClone = cloneDeep(this.getOrders)
        const result = (await OrdersApi.initialPromoCodeValidation(
          this.promoCode,
          ordersClone,
          this.getCustomer,
          this.preDiscountTotalWithGst
        ))
        this.promoCode = result.promoCode // fixes any whitespace or case inconsistencies
        this.promoCodeValidationError = null
        if (result.discount) this.promotionDiscount = getCostWithoutGst(result.discount, ordersClone[0].selectedDate)
        this.promotionFreeItem = result.freeItem && result.freeItem.name
      } catch(e) {
        this.promoCodeValidationError = e || this.$t('checkout.unexpectedError')
        this.promotionDiscount = null
        this.promotionFreeItem = null
      }
    },
    clearPromoCode() {
      this.promoCode = null
      this.promoCodeValidationError = null
      this.promotionDiscount = null
      this.promotionFreeItem = null
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";
@import "~@/assets/css/form-input";

.CheckoutPage {
  background-color: $white;

  .CheckoutHeader {
    padding: $space-xl 0 $space-xxl;

    @media only screen and (max-width: 640px) {
      padding: $space-m 0 $space-xl;

      img {
        margin-bottom: $space-xs;
      }
    }

    .Logotype {
      height: 18px;
      margin-right: $space-l;
      vertical-align: middle;
    }

    /deep/ .ProgressTracker {
      display: inline-block;
      vertical-align: middle;

      @media only screen and #{$tablet-down} {
        margin-top: $space-m;
        width: 100%;
      }
    }
  }
  .CheckoutBody {
    padding-bottom: $space-xxl;
    .OrderSummaries.MultipleOrders {
      .OrderSummary {
        margin-bottom: 1rem;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
      }
    }
    .OrdersTabulation {
      padding: 1rem;
      background-color: $gray-lightest;
      font-weight: bold;
      color: $ink;
    }
    .promo-code {
      padding: 1rem;
      color: $ink;

      .free-item-text {
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: $gray-darker;
        margin-left: 0.5rem;
      }

      .promo-code-wrapper {
        display: flex;
        position: relative;

        .promo-code-inner-wrapper {
          margin-right: 1rem;
          flex: 1;
          position: relative;

          input {
            margin-bottom: 0;
            padding-right: 16px;
            float: left;

            &.applied {
              border: none;
              padding-left: 0;
              cursor: default;
              pointer-events: none;
              width: unset;
            }
          }
        }

        .remove-promo-code {
          cursor: pointer;
          top: 0;
          position: absolute;
          right: 16px;

          &.applied {
            position: relative;
            float: left;
          }
        }

        span {
          float: right;
          line-height: 44px;
        }
      }
    }
    /deep/ .Divider {
      margin: $space-xl 0 $space-xl;
      height: 1px;
      background-color: $gray-lighter;
    }
    .OrderInfoForm {
      .Payment {
        h4 {
          display: block;
          margin-bottom: $space-m;
          @extend %display_medium;
        }
        label {
          display: block;
          margin-bottom: $space-m;

          input[type="radio"] {
            vertical-align: middle;
          }
          input[type="radio"] + span {
            @extend %body;
            vertical-align: middle;
            cursor: pointer;
          }
        }
        .PaymentWallet {
          margin-top: $space-l;

          &.Disabled {
            opacity: 0.4;
            pointer-events: none;

            /deep/ .PaymentWallet__feedback.error,
            /deep/ .PaymentWallet__actions {
              display: none;
            }
          }
          /deep/ .PaymentWallet__button {
            margin-bottom: 0;
          }
          & + .PlaceOrderButton {
            margin-top: $space-xl;
          }
        }
      }
    }
    .CheckoutError {
      margin-bottom: $space-s;
      padding: $space-s $space-xs;
      background-color: $red-light;
      border: 1px solid $red;
      border-radius: $round;
      color: $gray-darker;

      & + .PlaceOrderButton {
        margin-top: $space-m;
      }
    }
    .PlaceOrderButton {
      display: block;
      max-width: none;
      padding-left: 0;
      padding-right: 0;
      white-space: nowrap;
    }
  }
}
</style>
