export default {
  data() {
    return {
      paymentRequestCompleteMethod: null
    }
  },
  methods: {
    setPaymentRequestCompleteMethod(complete) {
      this.paymentRequestCompleteMethod = complete
    },
    completePaymentRequest(status) {
      if (this.paymentRequestCompleteMethod) {
        // this should fail safely to allow them to proceed, as payment/order has already been made/created
        try {
          this.paymentRequestCompleteMethod(status)
          this.paymentRequestCompleteMethod = null
        } catch(e) {}
      }
    }
  }
}
