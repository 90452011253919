<template>
  <div class="LocationConfigs"/>
</template>

<script>
import { SET_LOCATION_CONFIGS, SET_SPECIAL_LOCATION_DELIVERY_CHARGE } from '@/actions'
import LocationsApi from '@/api/LocationsApi'

export default {
  name: 'location-configs',
  created() {
    this.fetchLocationConfigs()
  },
  methods: {
    async fetchLocationConfigs() {
      const result = await LocationsApi.locationConfigs()
      if (result) {
        if (result.locationConfigs) {
          this.$store.dispatch(SET_LOCATION_CONFIGS, result.locationConfigs)
        }
        if (result.specialLocationDeliveryCharge) {
          this.$store.dispatch(SET_SPECIAL_LOCATION_DELIVERY_CHARGE, result.specialLocationDeliveryCharge)
        }
      }
    }
  }
}
</script>
