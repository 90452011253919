<template>
  <search-dropdown
    class="SearchGooglePlaces"
    :searchApi="searchApi"
    :options="searchResults"
    :getInputDisplay="getInputDisplay"
    :getOptionDisplay="getOptionDisplay"
    :getOptionDisplayRight="getOptionDisplayRight"
    :item="address"
    :placeholder="placeholder"
    :inputClass="inputClass"
    :disabled="disabled"
    :poweredByGoogle="true"
    @change="onChange"/>
</template>

<script>
import intersection from 'lodash/intersection'
import { getGooglePlace, importGooglePlacesScript, searchGooglePlaces } from '@/assets/js/google-places'
import AddressApi from '@/api/AddressApi'

const SearchDropdown = () => import('@/components/SearchDropdown')

const RELEVANT_TYPES = [
  'premise',
  'street_address',
  'establishment',
  'postal_code'
]

export default {
  name: 'search-google-places',
  components: {
    SearchDropdown
  },
  props: {
    type: {
      type: String,
      default: 'address'
    },
    address: {
      type: Object,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    inputClass: {
      type: String,
      default: 'DefaultInput'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchResults: []
    }
  },
  created() {
    importGooglePlacesScript()
  },
  methods: {
    removePostalCode(formattedAddress) {
      const match = formattedAddress.match(/Singapore \d{6}/)
      if (match) {
        return formattedAddress.substr(0, match.index - 2)
      }
      return formattedAddress
    },
    searchApi(searchTerm) {
      try {
        searchGooglePlaces(searchTerm, (predictions) => {
          if (!predictions || predictions.length === 0) {
            this.searchResults = []
            return
          }
          const relevantPredictions = predictions.filter((prediction) => {
            return intersection(RELEVANT_TYPES, prediction.types).length > 0
          })
          this.searchResults = relevantPredictions.map((prediction) => {
            return {
              id: prediction.placeId,
              address: prediction.description
            }
          })
        })
      } catch(e) {
        this.searchResults = []
      }
    },
    getInputDisplay(selectedItem) {
      if (this.type === 'postal_code') {
        return selectedItem.postalCode
      }
      return this.removePostalCode(selectedItem.formattedAddress || '')
    },
    getOptionDisplay(item) {
      return item.address || ''
    },
    getOptionDisplayRight() {
      return ''
    },
    onChange(address) {
      this.getGooglePlace(address)
    },
    getGooglePlace(address) {
      getGooglePlace(address.id, (place) => {
        let country
        let postalCode
        place.addressComponents.forEach((component) => {
          if (component.types.includes('country')) {
            country = component.longName
          } else if (component.types.includes('postal_code')) {
            postalCode = component.longName
          }
        })
        const placeData = {
          lng: place.geometry.location.lng(),
          lat: place.geometry.location.lat(),
          postalCode: postalCode,
          country: country,
          placeId: place.placeId,
          formattedAddress: place.formattedAddress,
          address: this.removePostalCode(place.formattedAddress)
        }
        if (place.types.includes('postal_code')) {
          this.getLocationFromPostalCode(placeData)
        } else {
          this.$emit('change', placeData)
        }
      })
    },
    async getLocationFromPostalCode(placeData) {
      const result = (await AddressApi.getLocationFromPostalCode({ placeData }))
      if (result.location) {
        placeData.formattedAddress = result.location.formattedAddress
        placeData.address = result.location.address || this.removePostalCode(result.location.formattedAddress)
      }
      this.$emit('change', placeData)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_shared_variables.sass";

.SearchGooglePlaces {
  &.SearchDropdown /deep/ .DefaultInput {
    padding: $space-s $space-xs;
    border-radius: 0;
  }
}
</style>
